body {
	background-color: white;

	font-family: Helvetica, Arial, sans-serif;
	font-size: 11pt;
	line-height: 1.5em;
}

img {
	border: none;
}
input {
    width: 40%;
    min-width: 200px;
    border-radius: 10px;
    border: 2px solid brown;
    padding: 10px;
    font-size: 14px;
}
h1, h2, h3 ,a{
	font-family: Georgia, Times, serif;
	color: brown;

	margin-top: 0;
	padding-top: 0;
}
.form-check-input{
	width: .25em;
  }
  .form-check-input:checked{
	background-color: burlywood;
	border-color: burlywood;
  }
h1 {
	 font-size: 1.3em;
	font-style:italic;
	font-size: larger; }
h2 { font-size: 1.2em; }
h3 { font-size: 1.1em; }

div.wrapper {
	
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

div.wrapper1 {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
div.wrapper4 {
	overflow-y: scroll;
}
div.wrapper3 {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	background-color: burlywood;

}
header {
	position: -webkit-sticky;
	position: sticky;
	left:0;
	top:0px;
}

.menu{
	align-self:center;
}
div.flex{
	display: flex;
	justify-content: center;

}
div.wrapper2 {
	margin-top: 50px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	z-index: 0;
}
div.header {
	background-color: burlywood;
	height: 100px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	top: 0;
	justify-content: left;
	width: 100%;
	position: fixed;
	z-index: 5000;
}
div#footer {
	background-color: burlywood;
	width: 100%;
    height: 100px;
    position: fixed;
    bottom: 0;
	width: 100%;
	
	
}
/* div#logo {

	padding-left: 10px;
	padding-top: 10px;
	border: none;

} */

/* banner image needs to snap to edge */


img#banner {
	float: right;
}

.menu{
float: right;

}
ul.nav{
	font-style:italic;
	font-size: larger;
	font-weight: 800;
}
p.note {
	font-size: 0.8em;
	line-height: 1.2em;
}


/* NAV BAR STYLE */

/* kludged up for IE7 compatibility */

div#nav {
	clear: both;
	font-family: Arial, Helvetica, sans-serif;
	margin-top: 5px;
	padding-top: 5px;
	height: 2em;
    
	background-color: burlywood;
	color:brown;

}

div#nav a {
	color: brown;
	background-color: burlywood;
}

ul.nav, ul.sub , ul.a {
	margin: 0px;
	padding: 0px;
	cursor: default;
	list-style-type: none;
}

ul.nav {
	margin-top: 2px;
}

li.top {
	display: inline;
	position: relative;
	padding: 2px 25px;
}

li.top:first-child {
	margin-left: 1em;
	padding-left: 1em;
}

ul.sub {
	display: none;
	position: absolute;
	top: 1.6em;
	left: 0px;
	min-width: 10em;
	font-size: 0.85em;
	text-align: center;
}

li.top:hover ul {
	display: block;
}

ul.sub a {
	display: block;
	line-height: 1.1em;
	padding: 0.5em 10px;
}

/* regular text in top-level menu */
li.top, li.top>a {
	color: brown;
	background-color: burlywood;
}

/* hover text in top-level menu */
ul.nav>li:hover, ul.nav>li:hover>a, ul.nav>li>a:hover {
	color: brown;
	background-color: burlywood;
}

/* regular text in dropdown area  */
ul.sub li, ul.sub li a {
	color: brown;
	background-color: burlywood;
}

/* hover text in dropdown area  */
ul.nav ul li:hover a, ul.nav ul li a:hover {
	color: brown;
}

/* dropdown box area */
ul.sub {
	border: 3px solid brown;
}

/* END NAV BAR STYLE */

div#content {
	margin-top: 5px;
}


th {
	padding-left: 5px;
	padding-right: 5px;
}
th.first {
	padding-right: 5px;
}

tr.first {
	margin-top: 60px;
}
p#footer {
	clear: both;

	color: #282828;
	background-color: black;

	font-size: 0.8em;
	text-align: right;
	vertical-align: top;
	position:absolute;
	margin-top: 5px;
	padding-right: 5px;
}
.tablediv{
	color:white;
	background-color: brown;

}


.firsttable{
	    width: 100%;		
		background-color: white;
		position: -webkit-sticky;
		position: sticky;
		z-index: 9000;
		left:0;
		top:100px;
	  }
  
	.secondtable{
        width: 100%;
		position: sticky;
		z-index: 1000;
		left:0;
		top:160px;
		background-color: white;
		height: 50px;
	  }

	  
.typeheadtext{

	width: 80%;
}

 
  
  
  
  .navbar {
	background-color: burlywood;
	height: 100px;
	display: block;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: fixed;
	top: 0;
	width:100%;
  }
  
  .nav-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 80px;
	max-width: 1500px;
  }
  
  .main-container{
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	color: brown;
	background-color: burlywood;
  }
  
  .nav-logo {
	align-items: center;
	margin-left: 20px;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	flex-grow: 1;
  }
  
  .nav-menu {
	display: flex;
	list-style: none;
	text-align: right;
	right: 0;
	float: right;
  }
  .title {
	text-align: center;
	font-size: 36px;
	line-height: 180%;
	flex:1 0 auto;
  }
  .rightalign{
	right:0;
	float: right;
  }
  .nav-links {
	color:brown;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;
	border-bottom: 3px solid transparent;
  }
  .fa-code {
	margin-left: 1rem;
  }
  
  .nav-item {
	line-height: 40px;
	margin-right: 1rem;
  }
  
  .nav-item:after {
	content: "";
	display: block;
	height: 3px;
	width: 0;
	background: transparent;
	transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-item:hover:after {
	width: 100%;
	background: brown;
  }
  
  .nav-item.active {
	color: brown;
	border: 1px solid brown;
  }
  
  .nav-icon {
	display: none;
  }
  .tablesdivmobile{
	display: none;
}
.title{
	display: block;
}
.tablesdiv{
	display:block;
}


  
  @media screen and (max-width: 960px) {
	div.wrapper2 {
		margin-top: 0px;
	}
	.navbar-collapse {
		width: auto;
		/* more code here */
	}

	
	.firsttable{
		background-color: burlywood;
		left:0;
		top:100px;
		margin: 0px;
		width: 100%;
	  } 
	  

	.secondtable{
		padding-bottom: 20px;
		left:0;
		top:140px;
		background-color: white;
	  }  
	
	.nav-menu {
	  display: flex;
	  flex-direction: column;
	  width: 100%;
	  border-top: 1pxsolid #fff;
	  position: absolute;
	  top: 80px;
	  left: -100%;
	  opacity: 1;
	  transition: all 0.5s ease;
	  z-index: 9999;
	}
	
	.nav-menu.active {
	  left: 0px;
	  opacity: 1;
	  transition: all 0.5s ease;
	 
	}
	.nav-item .active {
	  color: brown;
	  border: none;
	}
	.nav-links {
	  padding: 1.5rem;
	  width: 100%;
	  display: table;
	}
  
	.nav-icon {
	  display: block;
	  position: absolute;
	  top: 0;
	  right: 0;
	  transform: translate(-100%, 60%);
	  font-size: 1.8rem;
	  cursor: pointer;
	  color: brown;
	}
	.tablesdivmobile{
		display: block;
	}
	.tablesdiv{
		display: none;
	}
	.title{
		display: none;
	}
}
  
    @media (min-height: 480px) {
      header {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
      }
	  .firsttablemobile{
	    width: 100%;		
		background-color: white;
		z-index: 9000;
		left:0;
		height: auto;
	  }
	 .secondtablemobile{
        width: 100%;
		position: relative;
		z-index: 1000;
		left:0;
		background-color: white;
		top:100px;
        margin-bottom: 30px;
        height: 40px;
	  }
   
	 
    }

    @media (min-device-width: 576px) and (max-device-width: 1024px) and (orientation: landscape) {
      header {
        position: static;
      }
	  .firsttablemobile{
	    width: 100%;		
		background-color: white;
		z-index: 9000;
		left:0;
		position: relative;
		
	  }
	 .secondtablemobile{
        width: 100%;
		position: relative;
		z-index: 1000;
		left:0;
		top:100px;
        margin-bottom: 30px;
		background-color: white;
	  }
   
	 
    }

    footer { min-height: 130px; }

    @media (min-height: 480px) {
      footer {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
      }
    }

    @media (min-device-width: 576px) and (max-device-width: 1024px) and (orientation: landscape)  {
      footer {
        position: static;
      }
	  .firsttablemobile{
	    width: 100%;		
		background-color: white;
		z-index: 9000;
		left:0;
		height: auto;
	  }
  
	 .secondtablemobile{
        width: 100%;
		position: relative;
		z-index: 1000;
		left:0;
		background-color: white;
		top:100px;
		margin-bottom: 30px;


	  }

   
    }

  