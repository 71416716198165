.App {
  font-family: sans-serif;
  position: relative;
  width: 80vw;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  color: burlywood;
}

.heading {
  color: brown;
  border-bottom: 1px solid burlywood;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
input {
  width: 40%;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid brown;
  padding: 10px;
  font-size: 14px;
}
input[type='checkbox'] {
  width: 5%;
  min-width: 20px;
  
}
.textbox {
  width: 40%;
  min-width: 200px;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
}
.contactButton{
  background: beige;
  color: brown;
  border: none;
  padding: 2px;
  margin-left: 0px;
}
.contactButton hover{
  background: beige;
  color: brown;
  border: none;
  

}
button {
  height: 40px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  user-select: none;
  font-weight: 600;
  border-radius: 4px;
  color: brown;
  border: 1px solid burlywood;
  background-color: burlywood;
  cursor: pointer;
  margin: 0px 15px;
  min-width: 100px;
}


.tablefixed {
  padding: 1.5em;
  margin: 60px 0;
  border-collapse: collapse;
  box-shadow: 0 1px 2px 0 #c6e8c9;
}

thead {
  background-color: beige;
  font-size: 16px;
 }


thead tr {
  position: sticky;
  top: 0;
  text-align: center;
  padding: 5px;

}

th {
  text-align: center;
  background-color: 1px solid #ffffff;
  color: brown;
  position: sticky;
  top: 0;
  height: 30px;
}
td {
  text-align: center;
  padding: 0px;
}

tr.first {
  height: 0;
  background-color: white;
}
tr.second {
  height: 0;
  background-color: beige;

}

tr:nth-child(even) {
  background-color: #e0e0e0;
}
.tablefixed { 
  table-layout:fixed;
}

.firsttablediv{
  table-layout:fixed;

}
.sticky_col_head {
  position: -webkit-sticky;
  position: sticky;
  z-index: 9900;
  left:0;
  background-color: white;
}

.sticky_col_body {
  position: -webkit-sticky;
  position: sticky;
  z-index: 0;
  left:0;
}
td { 
  overflow: hidden; 
  text-overflow: ellipsis; 
  word-wrap: break-word;
}
.logotxt{
  
padding-top:20px;

}

.form-underline input[type='checkbox'] {
  width: auto;
  line-height: normal;
  height: auto;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 920px){
  .sticky_col_body {
		position: sticky;
		z-index: 0;
		left:10;}
    
   
	  }
    th {
      text-align: center;
      background-color: 1px solid #ffffff;
      color: brown;
      position: sticky;
      top: 0;
      height: 15px;
    }

@media screen and (max-width: 400px) {
	 
	.logotxt{
    text-align: center;
    padding-top:20px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 9998;
    left:0;
    }
}
@media screen and (min-width: 400px)  and (max-width: 720){
	 
  header{
    text-align: center;
    position: sticky;
    top: 0;
    background-color: burlywood;
    z-index: 9999;
  }
    .footer{
      position: relative;
      bottom: 0;
      width: fit-content;
    align-items: left;
    flex-direction: column;
    justify-content: center;
      
      }
      input[type='checkbox'] {
        width: 5%;
        min-width: 20px;
        
      }
}

@media screen and (min-width: 720px)  and (max-width: 1080){
  input[type='checkbox'] {
    width: 5%;
    min-width: 20px;
    
  }
 
    .sticky_col_body {
      position: sticky;
      z-index: 0;
      left:10;
  }
 
    .footer{
      position: fixed;
      bottom: 0;
      width: fit-content;
    align-items: left;
    flex-direction: column;
    justify-content: center;
      z-index: 999;
      }



}
@media only screen and (max-width: 480px) {
  /* horizontal scrollbar for tables if mobile screen */
  .tablemobile {
      overflow-x: auto;
      display: block;
  }
}