/* NAV BAR STYLE */

/* mobile version for tiny screens, no hovers, etc. */

/* need to override bunches of things from the default */

div#nav {
	clear: both;
	height:fit-content;
	width: 100%;
	overflow: hidden;
	font-family: Arial, Helvetica, sans-serif;
	color: brown;
	padding: 5px;
}

div#nav a {
	text-decoration: none;
	color: brown;
}

ul.nav, ul.sub {
	list-style-type: none;
	cursor: default;
	display: flex;
	position: relative;
	min-width: 0px;
	border: 0px;
}


li.top {
	display: block;
	float: left;
	padding: 5px;
	margin-right: 1em;
}

li.top:first-child {
	margin-left: 0px;
	padding-left: 0px;
}

ul.sub a {
	display: static;
	line-height: 1.1em;
	padding: 0.5em 10px;
}

ul.sub li, ul.sub li a {
	color: brown;
}

ul.nav>li:hover, ul.nav>li:hover>a, ul.nav>li>a:hover {
	color: brown;
}

ul.nav ul li:hover a, ul.nav ul li a:hover {
	color: burlywood;
}
.wrapper3{
	width: auto;
	
}
.form-control textbox{
	width: 50%;
}
