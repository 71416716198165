@import url(https://fonts.googleapis.com/css?family=Comfortaa);
@import url(https://use.typekit.net/clc6hgp.css);
@import url(https://fonts.googleapis.com/css?family=Poppins);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

body {
	background-color: white;

	font-family: Helvetica, Arial, sans-serif;
	font-size: 11pt;
	line-height: 1.5em;
}

img {
	border: none;
}
input {
    width: 40%;
    min-width: 200px;
    border-radius: 10px;
    border: 2px solid brown;
    padding: 10px;
    font-size: 14px;
}
h1, h2, h3 ,a{
	font-family: Georgia, Times, serif;
	color: brown;

	margin-top: 0;
	padding-top: 0;
}
.form-check-input{
	width: .25em;
  }
  .form-check-input:checked{
	background-color: burlywood;
	border-color: burlywood;
  }
h1 {
	 font-size: 1.3em;
	font-style:italic;
	font-size: larger; }
h2 { font-size: 1.2em; }
h3 { font-size: 1.1em; }

div.wrapper {
	
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

div.wrapper1 {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}
div.wrapper4 {
	overflow-y: scroll;
}
div.wrapper3 {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	background-color: burlywood;

}
header {
	position: -webkit-sticky;
	position: sticky;
	left:0;
	top:0px;
}

.menu{
	align-self:center;
}
div.flex{
	display: flex;
	justify-content: center;

}
div.wrapper2 {
	margin-top: 50px;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	z-index: 0;
}
div.header {
	background-color: burlywood;
	height: 100px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	top: 0;
	justify-content: left;
	width: 100%;
	position: fixed;
	z-index: 5000;
}
div#footer {
	background-color: burlywood;
	width: 100%;
    height: 100px;
    position: fixed;
    bottom: 0;
	width: 100%;
	
	
}
/* div#logo {

	padding-left: 10px;
	padding-top: 10px;
	border: none;

} */

/* banner image needs to snap to edge */


img#banner {
	float: right;
}

.menu{
float: right;

}
ul.nav{
	font-style:italic;
	font-size: larger;
	font-weight: 800;
}
p.note {
	font-size: 0.8em;
	line-height: 1.2em;
}


/* NAV BAR STYLE */

/* kludged up for IE7 compatibility */

div#nav {
	clear: both;
	font-family: Arial, Helvetica, sans-serif;
	margin-top: 5px;
	padding-top: 5px;
	height: 2em;
    
	background-color: burlywood;
	color:brown;

}

div#nav a {
	color: brown;
	background-color: burlywood;
}

ul.nav, ul.sub , ul.a {
	margin: 0px;
	padding: 0px;
	cursor: default;
	list-style-type: none;
}

ul.nav {
	margin-top: 2px;
}

li.top {
	display: inline;
	position: relative;
	padding: 2px 25px;
}

li.top:first-child {
	margin-left: 1em;
	padding-left: 1em;
}

ul.sub {
	display: none;
	position: absolute;
	top: 1.6em;
	left: 0px;
	min-width: 10em;
	font-size: 0.85em;
	text-align: center;
}

li.top:hover ul {
	display: block;
}

ul.sub a {
	display: block;
	line-height: 1.1em;
	padding: 0.5em 10px;
}

/* regular text in top-level menu */
li.top, li.top>a {
	color: brown;
	background-color: burlywood;
}

/* hover text in top-level menu */
ul.nav>li:hover, ul.nav>li:hover>a, ul.nav>li>a:hover {
	color: brown;
	background-color: burlywood;
}

/* regular text in dropdown area  */
ul.sub li, ul.sub li a {
	color: brown;
	background-color: burlywood;
}

/* hover text in dropdown area  */
ul.nav ul li:hover a, ul.nav ul li a:hover {
	color: brown;
}

/* dropdown box area */
ul.sub {
	border: 3px solid brown;
}

/* END NAV BAR STYLE */

div#content {
	margin-top: 5px;
}


th {
	padding-left: 5px;
	padding-right: 5px;
}
th.first {
	padding-right: 5px;
}

tr.first {
	margin-top: 60px;
}
p#footer {
	clear: both;

	color: #282828;
	background-color: black;

	font-size: 0.8em;
	text-align: right;
	vertical-align: top;
	position:absolute;
	margin-top: 5px;
	padding-right: 5px;
}
.tablediv{
	color:white;
	background-color: brown;

}


.firsttable{
	    width: 100%;		
		background-color: white;
		position: -webkit-sticky;
		position: sticky;
		z-index: 9000;
		left:0;
		top:100px;
	  }
  
	.secondtable{
        width: 100%;
		position: -webkit-sticky;
		position: sticky;
		z-index: 1000;
		left:0;
		top:160px;
		background-color: white;
		height: 50px;
	  }

	  
.typeheadtext{

	width: 80%;
}

 
  
  
  
  .navbar {
	background-color: burlywood;
	height: 100px;
	display: block;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: fixed;
	top: 0;
	width:100%;
  }
  
  .nav-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 80px;
	max-width: 1500px;
  }
  
  .main-container{
	position:absolute;
	top:0;
	bottom:0;
	left:0;
	right:0;
	color: brown;
	background-color: burlywood;
  }
  
  .nav-logo {
	align-items: center;
	margin-left: 20px;
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
	flex-grow: 1;
  }
  
  .nav-menu {
	display: flex;
	list-style: none;
	text-align: right;
	right: 0;
	float: right;
  }
  .title {
	text-align: center;
	font-size: 36px;
	line-height: 180%;
	flex:1 0 auto;
  }
  .rightalign{
	right:0;
	float: right;
  }
  .nav-links {
	color:brown;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;
	border-bottom: 3px solid transparent;
  }
  .fa-code {
	margin-left: 1rem;
  }
  
  .nav-item {
	line-height: 40px;
	margin-right: 1rem;
  }
  
  .nav-item:after {
	content: "";
	display: block;
	height: 3px;
	width: 0;
	background: transparent;
	transition: width 0.7s ease, background-color 0.5s ease;
  }
  
  .nav-item:hover:after {
	width: 100%;
	background: brown;
  }
  
  .nav-item.active {
	color: brown;
	border: 1px solid brown;
  }
  
  .nav-icon {
	display: none;
  }
  .tablesdivmobile{
	display: none;
}
.title{
	display: block;
}
.tablesdiv{
	display:block;
}


  
  @media screen and (max-width: 960px) {
	div.wrapper2 {
		margin-top: 0px;
	}
	.navbar-collapse {
		width: auto;
		/* more code here */
	}

	
	.firsttable{
		background-color: burlywood;
		left:0;
		top:100px;
		margin: 0px;
		width: 100%;
	  } 
	  

	.secondtable{
		padding-bottom: 20px;
		left:0;
		top:140px;
		background-color: white;
	  }  
	
	.nav-menu {
	  display: flex;
	  flex-direction: column;
	  width: 100%;
	  border-top: 1pxsolid #fff;
	  position: absolute;
	  top: 80px;
	  left: -100%;
	  opacity: 1;
	  transition: all 0.5s ease;
	  z-index: 9999;
	}
	
	.nav-menu.active {
	  left: 0px;
	  opacity: 1;
	  transition: all 0.5s ease;
	 
	}
	.nav-item .active {
	  color: brown;
	  border: none;
	}
	.nav-links {
	  padding: 1.5rem;
	  width: 100%;
	  display: table;
	}
  
	.nav-icon {
	  display: block;
	  position: absolute;
	  top: 0;
	  right: 0;
	  -webkit-transform: translate(-100%, 60%);
	          transform: translate(-100%, 60%);
	  font-size: 1.8rem;
	  cursor: pointer;
	  color: brown;
	}
	.tablesdivmobile{
		display: block;
	}
	.tablesdiv{
		display: none;
	}
	.title{
		display: none;
	}
}
  
    @media (min-height: 480px) {
      header {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
      }
	  .firsttablemobile{
	    width: 100%;		
		background-color: white;
		z-index: 9000;
		left:0;
		height: auto;
	  }
	 .secondtablemobile{
        width: 100%;
		position: relative;
		z-index: 1000;
		left:0;
		background-color: white;
		top:100px;
        margin-bottom: 30px;
        height: 40px;
	  }
   
	 
    }

    @media (min-device-width: 576px) and (max-device-width: 1024px) and (orientation: landscape) {
      header {
        position: static;
      }
	  .firsttablemobile{
	    width: 100%;		
		background-color: white;
		z-index: 9000;
		left:0;
		position: relative;
		
	  }
	 .secondtablemobile{
        width: 100%;
		position: relative;
		z-index: 1000;
		left:0;
		top:100px;
        margin-bottom: 30px;
		background-color: white;
	  }
   
	 
    }

    footer { min-height: 130px; }

    @media (min-height: 480px) {
      footer {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
      }
    }

    @media (min-device-width: 576px) and (max-device-width: 1024px) and (orientation: landscape)  {
      footer {
        position: static;
      }
	  .firsttablemobile{
	    width: 100%;		
		background-color: white;
		z-index: 9000;
		left:0;
		height: auto;
	  }
  
	 .secondtablemobile{
        width: 100%;
		position: relative;
		z-index: 1000;
		left:0;
		background-color: white;
		top:100px;
		margin-bottom: 30px;


	  }

   
    }

  



div.pagination {
	align-content: center;
	position:relative;	
	display: inline-block;	
}


div#banner {
	
	float: right;
	padding: 0px;
	margin: 0px;
	margin-left: 4px;
	height: 200px;
}

div#banner img {
	margin: 0px;
	padding: 0px;
	padding-right: 4px;
}

div#content {
	clear: both;
	overflow: auto;
	margin-top: 5px;
	padding-top: 5px;
	color: brown;
	background-color: white;
	border-radius: 10px;
	-webkit-border-radius: 10px;
}

div.column {
	width: 24%;
	margin-left: 3%;
	float: left;
	font-family: Arial, Helvetica, sans-serif;
}
#logobanner{
	background-color: burlywood;
    padding: 10px;
    display: block;
    box-shadow: 8px 7px 10px 0 rgba(19,18,18,.1);
    position: fixed;
    top: 84px;
    right: 0;
    left: 0;
    z-index: 1;
}
div.column h3 {
	font-size: 1.1em;
	color: #DDD;
}

div.column img {
	text-align: center;
}

div.column:first-child {
	margin-left: 1%;
	width: 15%;
	padding-top: 50px;
}

div.column a {
	color: white;
	text-decoration: none;
}
.form-control{
	width: 50%;
}



/* NAV BAR STYLE */

/* mobile version for tiny screens, no hovers, etc. */

/* need to override bunches of things from the default */

div#nav {
	clear: both;
	height:-webkit-fit-content;
	height:-moz-fit-content;
	height:fit-content;
	width: 100%;
	overflow: hidden;
	font-family: Arial, Helvetica, sans-serif;
	color: brown;
	padding: 5px;
}

div#nav a {
	text-decoration: none;
	color: brown;
}

ul.nav, ul.sub {
	list-style-type: none;
	cursor: default;
	display: flex;
	position: relative;
	min-width: 0px;
	border: 0px;
}


li.top {
	display: block;
	float: left;
	padding: 5px;
	margin-right: 1em;
}

li.top:first-child {
	margin-left: 0px;
	padding-left: 0px;
}

ul.sub a {
	display: static;
	line-height: 1.1em;
	padding: 0.5em 10px;
}

ul.sub li, ul.sub li a {
	color: brown;
}

ul.nav>li:hover, ul.nav>li:hover>a, ul.nav>li>a:hover {
	color: brown;
}

ul.nav ul li:hover a, ul.nav ul li a:hover {
	color: burlywood;
}
.wrapper3{
	width: auto;
	
}
.form-control textbox{
	width: 50%;
}

.App {
  font-family: sans-serif;
  position: relative;
  width: 80vw;
  margin-right: auto;
  margin-left: auto;
  margin-top: 30px;
  color: burlywood;
}

.heading {
  color: brown;
  border-bottom: 1px solid burlywood;
  margin-bottom: 30px;
  padding-bottom: 10px;
}
input {
  width: 40%;
  min-width: 200px;
  border-radius: 10px;
  border: 2px solid brown;
  padding: 10px;
  font-size: 14px;
}
input[type='checkbox'] {
  width: 5%;
  min-width: 20px;
  
}
.textbox {
  width: 40%;
  min-width: 200px;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
}
.contactButton{
  background: beige;
  color: brown;
  border: none;
  padding: 2px;
  margin-left: 0px;
}
.contactButton hover{
  background: beige;
  color: brown;
  border: none;
  

}
button {
  height: 40px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 15px;
  display: inline-block;
  text-align: center;
  -webkit-user-select: none;
          user-select: none;
  font-weight: 600;
  border-radius: 4px;
  color: brown;
  border: 1px solid burlywood;
  background-color: burlywood;
  cursor: pointer;
  margin: 0px 15px;
  min-width: 100px;
}


.tablefixed {
  padding: 1.5em;
  margin: 60px 0;
  border-collapse: collapse;
  box-shadow: 0 1px 2px 0 #c6e8c9;
}

thead {
  background-color: beige;
  font-size: 16px;
 }


thead tr {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  text-align: center;
  padding: 5px;

}

th {
  text-align: center;
  background-color: 1px solid #ffffff;
  color: brown;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 30px;
}
td {
  text-align: center;
  padding: 0px;
}

tr.first {
  height: 0;
  background-color: white;
}
tr.second {
  height: 0;
  background-color: beige;

}

tr:nth-child(even) {
  background-color: #e0e0e0;
}
.tablefixed { 
  table-layout:fixed;
}

.firsttablediv{
  table-layout:fixed;

}
.sticky_col_head {
  position: -webkit-sticky;
  position: sticky;
  z-index: 9900;
  left:0;
  background-color: white;
}

.sticky_col_body {
  position: -webkit-sticky;
  position: sticky;
  z-index: 0;
  left:0;
}
td { 
  overflow: hidden; 
  text-overflow: ellipsis; 
  word-wrap: break-word;
}
.logotxt{
  
padding-top:20px;

}

.form-underline input[type='checkbox'] {
  width: auto;
  line-height: normal;
  height: auto;
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 920px){
  .sticky_col_body {
		position: -webkit-sticky;
		position: sticky;
		z-index: 0;
		left:10;}
    
   
	  }
    th {
      text-align: center;
      background-color: 1px solid #ffffff;
      color: brown;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      height: 15px;
    }

@media screen and (max-width: 400px) {
	 
	.logotxt{
    text-align: center;
    padding-top:20px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 9998;
    left:0;
    }
}
@media screen and (min-width: 400px)  and (max-width: 720){
	 
  header{
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: burlywood;
    z-index: 9999;
  }
    .footer{
      position: relative;
      bottom: 0;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    align-items: left;
    flex-direction: column;
    justify-content: center;
      
      }
      input[type='checkbox'] {
        width: 5%;
        min-width: 20px;
        
      }
}

@media screen and (min-width: 720px)  and (max-width: 1080){
  input[type='checkbox'] {
    width: 5%;
    min-width: 20px;
    
  }
 
    .sticky_col_body {
      position: -webkit-sticky;
      position: sticky;
      z-index: 0;
      left:10;
  }
 
    .footer{
      position: fixed;
      bottom: 0;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
    align-items: left;
    flex-direction: column;
    justify-content: center;
      z-index: 999;
      }



}
@media only screen and (max-width: 480px) {
  /* horizontal scrollbar for tables if mobile screen */
  .tablemobile {
      overflow-x: auto;
      display: block;
  }
}
@charset "UTF-8";
/*!
 * Bootstrap Grid v4.0.0-beta.2 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 480px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  /*@include make-container();*/
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col-ms,
.col-ms-auto, .col-ms-12, .col-ms-11, .col-ms-10, .col-ms-9, .col-ms-8, .col-ms-7, .col-ms-6, .col-ms-5, .col-ms-4, .col-ms-3, .col-ms-2, .col-ms-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 480px) {
  .col-ms {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-ms-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-ms-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-ms-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-ms-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-ms-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-ms-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-ms-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-ms-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-ms-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-ms-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-ms-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-ms-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-ms-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-ms-first {
    order: -1;
  }
  .order-ms-1 {
    order: 1;
  }
  .order-ms-2 {
    order: 2;
  }
  .order-ms-3 {
    order: 3;
  }
  .order-ms-4 {
    order: 4;
  }
  .order-ms-5 {
    order: 5;
  }
  .order-ms-6 {
    order: 6;
  }
  .order-ms-7 {
    order: 7;
  }
  .order-ms-8 {
    order: 8;
  }
  .order-ms-9 {
    order: 9;
  }
  .order-ms-10 {
    order: 10;
  }
  .order-ms-11 {
    order: 11;
  }
  .order-ms-12 {
    order: 12;
  }
  .offset-ms-0 {
    margin-left: 0;
  }
  .offset-ms-1 {
    margin-left: 8.3333333333%;
  }
  .offset-ms-2 {
    margin-left: 16.6666666667%;
  }
  .offset-ms-3 {
    margin-left: 25%;
  }
  .offset-ms-4 {
    margin-left: 33.3333333333%;
  }
  .offset-ms-5 {
    margin-left: 41.6666666667%;
  }
  .offset-ms-6 {
    margin-left: 50%;
  }
  .offset-ms-7 {
    margin-left: 58.3333333333%;
  }
  .offset-ms-8 {
    margin-left: 66.6666666667%;
  }
  .offset-ms-9 {
    margin-left: 75%;
  }
  .offset-ms-10 {
    margin-left: 83.3333333333%;
  }
  .offset-ms-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 480px) {
  .flex-ms-row {
    flex-direction: row !important;
  }
  .flex-ms-column {
    flex-direction: column !important;
  }
  .flex-ms-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-ms-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-ms-wrap {
    flex-wrap: wrap !important;
  }
  .flex-ms-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-ms-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-ms-start {
    justify-content: flex-start !important;
  }
  .justify-content-ms-end {
    justify-content: flex-end !important;
  }
  .justify-content-ms-center {
    justify-content: center !important;
  }
  .justify-content-ms-between {
    justify-content: space-between !important;
  }
  .justify-content-ms-around {
    justify-content: space-around !important;
  }
  .align-items-ms-start {
    align-items: flex-start !important;
  }
  .align-items-ms-end {
    align-items: flex-end !important;
  }
  .align-items-ms-center {
    align-items: center !important;
  }
  .align-items-ms-baseline {
    align-items: baseline !important;
  }
  .align-items-ms-stretch {
    align-items: stretch !important;
  }
  .align-content-ms-start {
    align-content: flex-start !important;
  }
  .align-content-ms-end {
    align-content: flex-end !important;
  }
  .align-content-ms-center {
    align-content: center !important;
  }
  .align-content-ms-between {
    align-content: space-between !important;
  }
  .align-content-ms-around {
    align-content: space-around !important;
  }
  .align-content-ms-stretch {
    align-content: stretch !important;
  }
  .align-self-ms-auto {
    align-self: auto !important;
  }
  .align-self-ms-start {
    align-self: flex-start !important;
  }
  .align-self-ms-end {
    align-self: flex-end !important;
  }
  .align-self-ms-center {
    align-self: center !important;
  }
  .align-self-ms-baseline {
    align-self: baseline !important;
  }
  .align-self-ms-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
/*!
 * Bootstrap v4.0.0-beta.2 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0.25rem;
  transition: all 0.2s ease-in-out;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #868e96;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #868e96;
  opacity: 1;
}
.form-control::placeholder {
  color: #868e96;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px);
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.col-form-legend {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  font-size: 1rem;
}

.form-control-plaintext {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .form-control-plaintext.input-group-addon,
.input-group-sm > .input-group-btn > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .form-control-plaintext.input-group-addon,
.input-group-lg > .input-group-btn > .form-control-plaintext.btn {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > select.input-group-addon:not([size]):not([multiple]),
.input-group-sm > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px);
}

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > select.input-group-addon:not([size]):not([multiple]),
.input-group-lg > .input-group-btn > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  margin-bottom: 0.5rem;
}
.form-check.disabled .form-check-label {
  color: #868e96;
}

.form-check-label {
  padding-left: 1.25rem;
  margin-bottom: 0;
}

.form-check-input {
  position: absolute;
  margin-top: 0.25rem;
  margin-left: -1.25rem;
}

.form-check-inline {
  display: inline-block;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-label {
  vertical-align: middle;
}

.valid-feedback {
  display: none;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid + .form-check-label, .form-check-input.is-valid + .form-check-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-indicator, .custom-control-input.is-valid ~ .custom-control-indicator {
  background-color: rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid ~ .custom-control-description, .custom-control-input.is-valid ~ .custom-control-description {
  color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-control, .custom-file-input.is-valid ~ .custom-file-control {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .custom-file-control::before, .custom-file-input.is-valid ~ .custom-file-control::before {
  border-color: inherit;
}
.was-validated .custom-file-input:valid:focus, .custom-file-input.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  margin-top: 0.25rem;
  font-size: 0.875rem;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: 0.2rem;
}

.was-validated .form-control:invalid, .form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid + .form-check-label, .form-check-input.is-invalid + .form-check-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-indicator, .custom-control-input.is-invalid ~ .custom-control-indicator {
  background-color: rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid ~ .custom-control-description, .custom-control-input.is-invalid ~ .custom-control-description {
  color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-control, .custom-file-input.is-invalid ~ .custom-file-control {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .custom-file-control::before, .custom-file-input.is-invalid ~ .custom-file-control::before {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid:focus, .custom-file-input.is-invalid:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 768px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    margin-top: 0;
    margin-bottom: 0;
  }
  .form-inline .form-check-label {
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  .form-inline .custom-control-indicator {
    position: static;
    display: inline-block;
    margin-right: 0.25rem;
    vertical-align: text-bottom;
  }
  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
  .form-control{
    width: 60%;
  }
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
}
.fade.show {
  opacity: 1;
}

.collapse {
  display: none;
}
.collapse.show {
  display: block;
}

tr.collapse.show {
  display: table-row;
}

tbody.collapse.show {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.dropup,
.dropdown {
  position: relative;
}

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 9000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;
}
.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #868e96;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap;
}

.input-group {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}
.input-group .form-control {
  position: relative;
  z-index: 2;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group .form-control:focus, .input-group .form-control:active, .input-group .form-control:hover {
  z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: flex;
  align-items: center;
}
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  white-space: nowrap;
}

.input-group-addon {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-addon.form-control-sm,
.input-group-sm > .input-group-addon,
.input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.input-group-addon.form-control-lg,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.input-group-addon input[type=radio],
.input-group-addon input[type=checkbox] {
  margin-top: 0;
}

.input-group .form-control:not(:last-child),
.input-group-addon:not(:last-child),
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group > .btn,
.input-group-btn:not(:last-child) > .dropdown-toggle,
.input-group-btn:not(:first-child) > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:not(:first-child) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group .form-control:not(:first-child),
.input-group-addon:not(:first-child),
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group > .btn,
.input-group-btn:not(:first-child) > .dropdown-toggle,
.input-group-btn:not(:last-child) > .btn:not(:first-child),
.input-group-btn:not(:last-child) > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-control + .input-group-addon:not(:first-child) {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  align-items: stretch;
  font-size: 0;
  white-space: nowrap;
}
.input-group-btn > .btn {
  position: relative;
}
.input-group-btn > .btn + .btn {
  margin-left: -1px;
}
.input-group-btn > .btn:focus, .input-group-btn > .btn:active, .input-group-btn > .btn:hover {
  z-index: 3;
}
.input-group-btn:first-child > .btn + .btn {
  margin-left: 0;
}
.input-group-btn:not(:last-child) > .btn,
.input-group-btn:not(:last-child) > .btn-group {
  margin-right: -1px;
}
.input-group-btn:not(:first-child) > .btn,
.input-group-btn:not(:first-child) > .btn-group {
  z-index: 2;
  margin-left: 0;
}
.input-group-btn:not(:first-child) > .btn:first-child,
.input-group-btn:not(:first-child) > .btn-group:first-child {
  margin-left: -1px;
}
.input-group-btn:not(:first-child) > .btn:focus, .input-group-btn:not(:first-child) > .btn:active, .input-group-btn:not(:first-child) > .btn:hover,
.input-group-btn:not(:first-child) > .btn-group:focus,
.input-group-btn:not(:first-child) > .btn-group:active,
.input-group-btn:not(:first-child) > .btn-group:hover {
  z-index: 3;
}

.custom-control {
  position: relative;
  display: inline-flex;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-indicator {
  color: #fff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:active ~ .custom-control-indicator {
  color: #fff;
  background-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-indicator {
  background-color: #e9ecef;
}
.custom-control-input:disabled ~ .custom-control-description {
  color: #868e96;
}

.custom-control-indicator {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox .custom-control-indicator {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-indicator {
  background-color: #007bff;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

.custom-radio .custom-control-indicator {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-indicator {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

.custom-controls-stacked {
  display: flex;
  flex-direction: column;
}
.custom-controls-stacked .custom-control {
  margin-bottom: 0.25rem;
}
.custom-controls-stacked .custom-control + .custom-control {
  margin-left: 0;
}

.custom-select {
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: none;
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple] {
  height: auto;
  background-image: none;
}
.custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}

.custom-file {
  position: relative;
  display: inline-block;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  min-width: 14rem;
  max-width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-control {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #007bff;
}

.custom-file-control {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-control:lang(en):empty::after {
  content: "Choose file...";
}
.custom-file-control::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  z-index: 6;
  display: block;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-file-control:lang(en)::before {
  content: "Browse";
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:focus, .nav-link:hover {
  text-decoration: none;
}
.nav-link.disabled {
  color: #868e96;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #ddd;
}
.nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #ddd #ddd #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}


.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
          transform: translate(0, -25%);
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 15px;
  margin: -15px -15px -15px auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 15px;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-top: 1px solid #e9ecef;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
  input {
    width: 40%;
    min-width: 200px;
    border-radius: 10px;
    border: 2px solid brown;
    padding: 10px;
    font-size: 14px;
}
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 1200px) {
  .modal-lg {
    max-width: 800px;
  }
}
.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}

.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
  }
}

.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:focus, .carousel-control-prev:hover,
.carousel-control-next:focus,
.carousel-control-next:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 480px) {
  .d-ms-none {
    display: none !important;
  }
  .d-ms-inline {
    display: inline !important;
  }
  .d-ms-inline-block {
    display: inline-block !important;
  }
  .d-ms-block {
    display: block !important;
  }
  .d-ms-table {
    display: table !important;
  }
  .d-ms-table-row {
    display: table-row !important;
  }
  .d-ms-table-cell {
    display: table-cell !important;
  }
  .d-ms-flex {
    display: flex !important;
  }
  .d-ms-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
.d-print-block {
  display: none !important;
}
@media print {
  .d-print-block {
    display: block !important;
  }
}

.d-print-inline {
  display: none !important;
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
}

.d-print-inline-block {
  display: none !important;
}
@media print {
  .d-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 480px) {
  .flex-ms-row {
    flex-direction: row !important;
  }
  .flex-ms-column {
    flex-direction: column !important;
  }
  .flex-ms-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-ms-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-ms-wrap {
    flex-wrap: wrap !important;
  }
  .flex-ms-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-ms-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-ms-start {
    justify-content: flex-start !important;
  }
  .justify-content-ms-end {
    justify-content: flex-end !important;
  }
  .justify-content-ms-center {
    justify-content: center !important;
  }
  .justify-content-ms-between {
    justify-content: space-between !important;
  }
  .justify-content-ms-around {
    justify-content: space-around !important;
  }
  .align-items-ms-start {
    align-items: flex-start !important;
  }
  .align-items-ms-end {
    align-items: flex-end !important;
  }
  .align-items-ms-center {
    align-items: center !important;
  }
  .align-items-ms-baseline {
    align-items: baseline !important;
  }
  .align-items-ms-stretch {
    align-items: stretch !important;
  }
  .align-content-ms-start {
    align-content: flex-start !important;
  }
  .align-content-ms-end {
    align-content: flex-end !important;
  }
  .align-content-ms-center {
    align-content: center !important;
  }
  .align-content-ms-between {
    align-content: space-between !important;
  }
  .align-content-ms-around {
    align-content: space-around !important;
  }
  .align-content-ms-stretch {
    align-content: stretch !important;
  }
  .align-self-ms-auto {
    align-self: auto !important;
  }
  .align-self-ms-start {
    align-self: flex-start !important;
  }
  .align-self-ms-end {
    align-self: flex-end !important;
  }
  .align-self-ms-center {
    align-self: center !important;
  }
  .align-self-ms-baseline {
    align-self: baseline !important;
  }
  .align-self-ms-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 480px) {
  .float-ms-left {
    float: left !important;
  }
  .float-ms-right {
    float: right !important;
  }
  .float-ms-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-6 {
  margin: 6rem !important;
}

.mt-6,
.my-6 {
  margin-top: 6rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 6rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 6rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 6rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-6 {
  padding: 6rem !important;
}

.pt-6,
.py-6 {
  padding-top: 6rem !important;
}

.pr-6,
.px-6 {
  padding-right: 6rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 6rem !important;
}

.pl-6,
.px-6 {
  padding-left: 6rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 480px) {
  .m-ms-0 {
    margin: 0 !important;
  }
  .mt-ms-0,
  .my-ms-0 {
    margin-top: 0 !important;
  }
  .mr-ms-0,
  .mx-ms-0 {
    margin-right: 0 !important;
  }
  .mb-ms-0,
  .my-ms-0 {
    margin-bottom: 0 !important;
  }
  .ml-ms-0,
  .mx-ms-0 {
    margin-left: 0 !important;
  }
  .m-ms-1 {
    margin: 0.25rem !important;
  }
  .mt-ms-1,
  .my-ms-1 {
    margin-top: 0.25rem !important;
  }
  .mr-ms-1,
  .mx-ms-1 {
    margin-right: 0.25rem !important;
  }
  .mb-ms-1,
  .my-ms-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-ms-1,
  .mx-ms-1 {
    margin-left: 0.25rem !important;
  }
  .m-ms-2 {
    margin: 0.5rem !important;
  }
  .mt-ms-2,
  .my-ms-2 {
    margin-top: 0.5rem !important;
  }
  .mr-ms-2,
  .mx-ms-2 {
    margin-right: 0.5rem !important;
  }
  .mb-ms-2,
  .my-ms-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-ms-2,
  .mx-ms-2 {
    margin-left: 0.5rem !important;
  }
  .m-ms-3 {
    margin: 1rem !important;
  }
  .mt-ms-3,
  .my-ms-3 {
    margin-top: 1rem !important;
  }
  .mr-ms-3,
  .mx-ms-3 {
    margin-right: 1rem !important;
  }
  .mb-ms-3,
  .my-ms-3 {
    margin-bottom: 1rem !important;
  }
  .ml-ms-3,
  .mx-ms-3 {
    margin-left: 1rem !important;
  }
  .m-ms-4 {
    margin: 1.5rem !important;
  }
  .mt-ms-4,
  .my-ms-4 {
    margin-top: 1.5rem !important;
  }
  .mr-ms-4,
  .mx-ms-4 {
    margin-right: 1.5rem !important;
  }
  .mb-ms-4,
  .my-ms-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-ms-4,
  .mx-ms-4 {
    margin-left: 1.5rem !important;
  }
  .m-ms-5 {
    margin: 3rem !important;
  }
  .mt-ms-5,
  .my-ms-5 {
    margin-top: 3rem !important;
  }
  .mr-ms-5,
  .mx-ms-5 {
    margin-right: 3rem !important;
  }
  .mb-ms-5,
  .my-ms-5 {
    margin-bottom: 3rem !important;
  }
  .ml-ms-5,
  .mx-ms-5 {
    margin-left: 3rem !important;
  }
  .m-ms-6 {
    margin: 6rem !important;
  }
  .mt-ms-6,
  .my-ms-6 {
    margin-top: 6rem !important;
  }
  .mr-ms-6,
  .mx-ms-6 {
    margin-right: 6rem !important;
  }
  .mb-ms-6,
  .my-ms-6 {
    margin-bottom: 6rem !important;
  }
  .ml-ms-6,
  .mx-ms-6 {
    margin-left: 6rem !important;
  }
  .p-ms-0 {
    padding: 0 !important;
  }
  .pt-ms-0,
  .py-ms-0 {
    padding-top: 0 !important;
  }
  .pr-ms-0,
  .px-ms-0 {
    padding-right: 0 !important;
  }
  .pb-ms-0,
  .py-ms-0 {
    padding-bottom: 0 !important;
  }
  .pl-ms-0,
  .px-ms-0 {
    padding-left: 0 !important;
  }
  .p-ms-1 {
    padding: 0.25rem !important;
  }
  .pt-ms-1,
  .py-ms-1 {
    padding-top: 0.25rem !important;
  }
  .pr-ms-1,
  .px-ms-1 {
    padding-right: 0.25rem !important;
  }
  .pb-ms-1,
  .py-ms-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-ms-1,
  .px-ms-1 {
    padding-left: 0.25rem !important;
  }
  .p-ms-2 {
    padding: 0.5rem !important;
  }
  .pt-ms-2,
  .py-ms-2 {
    padding-top: 0.5rem !important;
  }
  .pr-ms-2,
  .px-ms-2 {
    padding-right: 0.5rem !important;
  }
  .pb-ms-2,
  .py-ms-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-ms-2,
  .px-ms-2 {
    padding-left: 0.5rem !important;
  }
  .p-ms-3 {
    padding: 1rem !important;
  }
  .pt-ms-3,
  .py-ms-3 {
    padding-top: 1rem !important;
  }
  .pr-ms-3,
  .px-ms-3 {
    padding-right: 1rem !important;
  }
  .pb-ms-3,
  .py-ms-3 {
    padding-bottom: 1rem !important;
  }
  .pl-ms-3,
  .px-ms-3 {
    padding-left: 1rem !important;
  }
  .p-ms-4 {
    padding: 1.5rem !important;
  }
  .pt-ms-4,
  .py-ms-4 {
    padding-top: 1.5rem !important;
  }
  .pr-ms-4,
  .px-ms-4 {
    padding-right: 1.5rem !important;
  }
  .pb-ms-4,
  .py-ms-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-ms-4,
  .px-ms-4 {
    padding-left: 1.5rem !important;
  }
  .p-ms-5 {
    padding: 3rem !important;
  }
  .pt-ms-5,
  .py-ms-5 {
    padding-top: 3rem !important;
  }
  .pr-ms-5,
  .px-ms-5 {
    padding-right: 3rem !important;
  }
  .pb-ms-5,
  .py-ms-5 {
    padding-bottom: 3rem !important;
  }
  .pl-ms-5,
  .px-ms-5 {
    padding-left: 3rem !important;
  }
  .p-ms-6 {
    padding: 6rem !important;
  }
  .pt-ms-6,
  .py-ms-6 {
    padding-top: 6rem !important;
  }
  .pr-ms-6,
  .px-ms-6 {
    padding-right: 6rem !important;
  }
  .pb-ms-6,
  .py-ms-6 {
    padding-bottom: 6rem !important;
  }
  .pl-ms-6,
  .px-ms-6 {
    padding-left: 6rem !important;
  }
  .m-ms-auto {
    margin: auto !important;
  }
  .mt-ms-auto,
  .my-ms-auto {
    margin-top: auto !important;
  }
  .mr-ms-auto,
  .mx-ms-auto {
    margin-right: auto !important;
  }
  .mb-ms-auto,
  .my-ms-auto {
    margin-bottom: auto !important;
  }
  .ml-ms-auto,
  .mx-ms-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .m-sm-6 {
    margin: 6rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 6rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 6rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 6rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 6rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .p-sm-6 {
    padding: 6rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 6rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 6rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 6rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 6rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .m-md-6 {
    margin: 6rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 6rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 6rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 6rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 6rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .p-md-6 {
    padding: 6rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 6rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 6rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 6rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 6rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .m-lg-6 {
    margin: 6rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 6rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 6rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 6rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 6rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .p-lg-6 {
    padding: 6rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 6rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 6rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 6rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 6rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
.text-justify {
  text-align: justify !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 480px) {
  .text-ms-left {
    text-align: left !important;
  }
  .text-ms-right {
    text-align: right !important;
  }
  .text-ms-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/*$bp-ms: strip-unit(map-get($grid-breakpoints, ms));
$bp-ms-max: strip-unit(map-get($grid-breakpoints, ms)) - 1;
$bp-sm: strip-unit(map-get($grid-breakpoints, sm));
$bp-sm-max: strip-unit(map-get($grid-breakpoints, sm)) - 1;
$bp-md: strip-unit(map-get($grid-breakpoints, md));
$bp-md-max: strip-unit(map-get($grid-breakpoints, md)) - 1;
$bp-lg: strip-unit(map-get($grid-breakpoints, lg));*/
/*$section-padding: (
    base: 2.5rem,
    sm: 4.375rem,
    md: 5.625rem
);
$section-padding-half: (
    base: 1.25rem,
    sm: 2.188rem,
    md: 2.813rem
);*/
.container-fluid {
  padding: 0 112px;
  margin: 0;
}
@media screen and (max-width: 1570px) {
  .container-fluid {
    padding: 0 20px;
  }
}
@media screen and (max-width: 1520px) {
  .container-fluid {
    padding: 0 15px;
  }
}
@media screen and (max-width: 1390px) {
  .container-fluid {
    padding: 0 10px;
  }
}
@media screen and (max-width: 1150px) {
  .container-fluid {
    padding: 0 10px;
  }
}

.content {
  position: relative;
  min-height: calc(100vh - 233px);
  padding: 0;
}

@media (min-width: 1131px) {
  .content {
    min-height: calc(100vh - 231px);
    padding: 0;
  }
}
@media (min-width: 1400px) {
  .content {
    min-height: calc(100vh - 231px);
    padding: 0;
  }
}
@media screen and (min-width: 1130px) {
  .add-top-margin {
    padding-top: 103px;
  }
}

.btn-animate-right {
  transition: all 0.5s;
}
.btn-animate-right:hover .fa-arrow-circle-right {
  padding-right: 0px;
}
.btn-animate-right:hover span:after {
  opacity: 1;
  right: 0;
}
.btn-animate-right span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}
.btn-animate-right span:after {
  content: "";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}
.btn-animate-right .fa-arrow-circle-right {
  float: right;
  padding-top: 5px;
  padding-right: 10px;
}

.btn-animate-left {
  transition: all 0.5s;
}
.btn-animate-left:hover .fa-arrow-circle-left {
  padding-left: 0px;
}
.btn-animate-left .fa-arrow-circle-left {
  float: left;
  padding-top: 6px;
  padding-left: 20px;
}

.addMarginTop {
  margin-top: 7.5em;
}

.container {
  width: 100%;
  top: 40%;
  left: 0;
  margin: 0 auto;
  font-family: Poppins;
}
.container p {
  font-size: 15px;
}
.container a {
  display: inline-block;
  position: relative;
  text-align: center;
  color: #2173B7;
  text-decoration: none;
  font-size: 20px;
  overflow: hidden;
  top: 5px;
  /*&:after {
      content: '';
      position: absolute;
      background: $color-main;
      height: 2px;
      width: 0%;
      transform: translateX(-50%);
      left: 50%;
      bottom: 0;
      transition: .35s ease;
  }*/
}
.container a:hover:after, .container a:focus:after, .container a:active:after {
  width: 100%;
}

.upper-nav-container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  z-index: 3;
  background-color: burlywood;
  height: 100px;
  /* header */
  /* menu */
  /* .header .customer-login a,
  .header .make-claim a {
      color: $primary-blue;
      padding: 10px 21px;
  }*/
  /* menu icon */
  /* menu btn */
  /* 48em = 768px */
}
@media screen and (max-width: 1130px) {
  .upper-nav-container {
    height: 74px;
    position: fixed;
    right: 0;
  }
}
@media screen and (max-width: 1129px) {
  .upper-nav-container {
    height: 70px;
  }
}
.upper-nav-container .button_container {
  position: absolute;
  top: 40px;
  right: 40px;
  height: 27px;
  width: 35px;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;
}
.upper-nav-container .button_container:hover {
  opacity: 0.7;
}
.upper-nav-container .button_container.active {
  background: none;
}
.upper-nav-container .button_container.active .top {
  -webkit-transform: translateY(11px) translateX(0) rotate(45deg);
          transform: translateY(11px) translateX(0) rotate(45deg);
  background: #FFDC07;
}
.upper-nav-container .button_container.active .middle {
  opacity: 0;
  background: #FFDC07;
}
.upper-nav-container .button_container.active .bottom {
  -webkit-transform: translateY(-11px) translateX(0) rotate(-45deg);
          transform: translateY(-11px) translateX(0) rotate(-45deg);
  background: #FFDC07;
}
.upper-nav-container .button_container span {
  background: #2173B7;
  border: none;
  height: 5px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.35s ease;
  cursor: pointer;
}
.upper-nav-container .button_container span:nth-of-type(2) {
  top: 11px;
}
.upper-nav-container .button_container span:nth-of-type(3) {
  top: 22px;
}
.upper-nav-container .overlay {
  position: fixed;
  background: #2173B7;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
}
.upper-nav-container .overlay.open {
  opacity: 1;
  visibility: visible;
  height: 100%;
  z-index: 5;
}
.upper-nav-container .overlay.open li {
  -webkit-animation: fadeInRight 0.5s ease forwards;
          animation: fadeInRight 0.5s ease forwards;
  -webkit-animation-delay: 0.35s;
          animation-delay: 0.35s;
  font-family: Poppins;
}
.upper-nav-container .overlay.open li:nth-of-type(2) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.upper-nav-container .overlay.open li:nth-of-type(3) {
  -webkit-animation-delay: 0.45s;
          animation-delay: 0.45s;
}
.upper-nav-container .overlay.open li:nth-of-type(4) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
.upper-nav-container .overlay nav {
  position: relative;
  height: 30px;
  top: 20%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 24px;
  font-family: "Vollkorn", serif;
  font-weight: 400;
  text-align: center;
}
.upper-nav-container .overlay ul {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  display: inline-block;
  position: relative;
  height: 100%;
}
.upper-nav-container .overlay ul li {
  display: block;
  height: 25%;
  height: 25%;
  min-height: 50px;
  position: relative;
  opacity: 100;
}
.upper-nav-container .overlay ul li a {
  display: block;
  position: relative;
  color: #FFDC07;
  text-decoration: none;
  overflow: hidden;
}
.upper-nav-container .overlay ul li a:hover:after, .upper-nav-container .overlay ul li a:focus:after, .upper-nav-container .overlay ul li a:active:after {
  width: 100%;
}
.upper-nav-container .overlay ul li a:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  height: 3px;
  background: #FFDC07;
  transition: 0.35s;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}
.upper-nav-container .header {
  background-color: burlywood;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 3;
  height: 120px;
}
.upper-nav-container .header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  /* overflow: hidden;*/ /*commented to display submenu*/
  background-color: burlywood;
}
.upper-nav-container .header li a {
  display: block;
  padding: 20px 20px;
  text-decoration: none;
  font-size: 18px;
  font-family: "Comfortaa";
  opacity: 1;
  font-style: normal;
}
.upper-nav-container .header li a:hover,
.upper-nav-container .header .menu-btn:hover {
  color: #F15A23;
}
.upper-nav-container .header .logo {
  display: block;
  float: left;
  max-width: 210px;
  font-size: 2em;
  text-decoration: none;
  margin-top: 1rem;
}
@media (width: 810px) and (height: 1010px) {
  .upper-nav-container .header .logo {
    max-width: 100px !important;
  }
}
.upper-nav-container .header .logo img {
  width: 100%;
}
.upper-nav-container .header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  margin-top: 1.1rem;
}
.upper-nav-container .header .menu a {
  color: #454545;
}
.upper-nav-container .header .menu .dropdown-menu {
  border-radius: 12px;
  border: 1px solid #A4A6B2;
  padding: 0;
}
.upper-nav-container .header .menu .dropdown-menu a {
  color: #F15A23;
}
.upper-nav-container .header .menu .dropdown-menu a:first-of-type {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.upper-nav-container .header .menu .dropdown-menu a:last-of-type {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.upper-nav-container .header .menu .dropdown-menu a:hover {
  color: #FFFFFF;
  background-color: #F15A23;
}
.upper-nav-container .dropdown-divider {
  margin: 0;
}
.upper-nav-container .header .customer-login, .upper-nav-container .header .make-claim {
  /*background-color: $yellow;*/
  /*color: $primary-blue;*/
  border-radius: 52px;
}
.upper-nav-container .header .make-claim {
  background-color: rgba(241, 90, 35, 0);
  color: #F15A23;
  margin-left: 12px;
  border: 1px solid #F15A23;
  opacity: 1;
}
.upper-nav-container .header .make-claim a {
  color: #F15A23;
  padding: 0px 8px;
}
.upper-nav-container .header .customer-login:hover {
  background-color: #2173B7;
  color: #FFDC07;
}
.upper-nav-container .header .make-claim:hover {
  background-color: #F15A23;
}
.upper-nav-container .header .make-claim:hover a {
  color: #FFFFFF;
}
.upper-nav-container .header .menu-icon {
  cursor: pointer;
  display: inline-block;
  padding: 33px 20px;
  padding-right: 0;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
}
.upper-nav-container .header .menu-icon .navicon {
  background: #2173B7;
  display: block;
  height: 3px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 40px;
}
.upper-nav-container .header .menu-icon .navicon:before,
.upper-nav-container .header .menu-icon .navicon:after {
  background: #2173B7;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}
.upper-nav-container .header .menu-icon .navicon:before {
  top: 9px;
}
.upper-nav-container .header .menu-icon .navicon:after {
  top: -9px;
}
.upper-nav-container .header .menu-btn {
  display: none;
}
.upper-nav-container .header .menu-btn:checked ~ .menu {
  min-height: 720px;
  background-color: #2173B7;
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
.upper-nav-container .header .menu-btn:checked ~ .menu a {
  color: #FFDC07;
}
.upper-nav-container .header .menu-btn:checked ~ .menu .customer-login a {
  color: #2173B7;
  text-align: center;
}
.upper-nav-container .header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}
.upper-nav-container .header .menu-btn:checked ~ .menu-icon .navicon:before {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.upper-nav-container .header .menu-btn:checked ~ .menu-icon .navicon:after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.upper-nav-container .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.upper-nav-container .header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}
@media (min-width: 1130px) {
  .upper-nav-container .header li {
    float: left;
  }
  .upper-nav-container .header li a {
    padding: 10px 14px;
  }
  .upper-nav-container .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .upper-nav-container .header .top-menu {
    clear: none;
    float: right;
    max-height: none;
    margin-top: 27px;
  }
  .upper-nav-container .header .menu-icon {
    display: none;
  }
  .upper-nav-container .header .logo {
    max-width: 100px;
  }
}
@media (min-width: 1130px) and (max-width: 1350px) {
  .upper-nav-container .header .top-menu {
    margin-top: 27px;
  }
  .upper-nav-container .header .menu {
    max-height: none;
  }
  .upper-nav-container .header li {
    font-size: 13px; /* dev ref : size of heder menu*/
    font-family: "Comfortaa";
    opacity: 1;
    font-style: normal;
    font-weight: 600;
  }
  .upper-nav-container .header li a {
    padding-left: 8px;
    padding-right: 8px;
  }
  .upper-nav-container li.nav-item {
    padding: 0px 13px 0px 13px;
  }
}
@media (min-width: 1350px) and (max-width: 1470px) {
  .upper-nav-container .header .menu {
    max-height: none;
  }
  .upper-nav-container .header li a {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 13px;
    font-family: "Comfortaa";
    opacity: 1;
    font-style: normal;
  }
}
@media (min-width: 1470px) and (max-width: 1550px) {
  .upper-nav-container .header li a {
    padding-left: 12px;
    padding-right: 12px;
    font-size: 13px;
    font-family: "Comfortaa";
    opacity: 1;
    font-style: normal;
  }
}
@media (min-width: 1570px) and (max-width: 1600px) {
  .upper-nav-container .header li a {
    padding-left: 11px;
    padding-right: 10px;
    font-size: 13px;
    font-family: "Comfortaa";
    opacity: 1;
    font-style: normal;
  }
}
@media (max-width: 1130px) {
  .upper-nav-container .header .logo {
    max-width: 200px;
  }
}
@media (max-width: 768px) {
  .upper-nav-container .header .logo {
    max-width: 100px;
  }
}
@media (min-width: 820px) and (max-width: 1024px) {
  .upper-nav-container .header .logo {
    max-width: 100px;
  }
}

@media (max-width: 1129px) {
  .upper-nav-container .header li a {
    padding: 12px 20px;
  }
  .upper-nav-container .header .menu {
    margin-top: 0;
    z-index: 10;
  }
  .upper-nav-container .header {
    height: auto;
  }
  .upper-nav-container .header .make-claim,
  .upper-nav-container .header .customer-login {
    margin: 12px 20px;
    text-align: center;
    background-color: #FFFFFF;
  }
  .upper-nav-container .header .make-claim a,
  .upper-nav-container .header .customer-login a {
    padding: 12px;
    color: #F15A23;
    text-align: center;
    border: none;
    font-weight: 600;
  }
  .upper-nav-container .header .make-claim a:hover, .upper-nav-container .header .make-claim a:active,
  .upper-nav-container .header .customer-login a:hover,
  .upper-nav-container .header .customer-login a:active {
    color: #FFFFFF;
  }
  .upper-nav-container .header .make-claim:hover {
    background-color: #F15A23;
  }
  .upper-nav-container .header .make-claim:hover a {
    color: #FFFFFF;
    background-color: #F15A23;
    border-radius: 52px;
    border: 1px solid #FFFFFF;
    text-align: center;
    border: none;
  }
  /*.upper-nav-container .header .make-claim .nav-link:hover {
      color: white;
      background: $primary-orange;
      border-radius: 52px;
      border: 1px solid white;
  }*/
}
.cc-grower {
  position: absolute;
  width: 100%;
}

@media (min-width: 1130px) and (max-width: 1224px) {
  .upper-nav-container .header li a {
    padding: 10px;
    font-size: 11px;
  }
  .upper-nav-container .header .customer-login a, .upper-nav-container .header .make-claim a {
    padding: 10px 14px;
  }
}
.navbar {
  /* margin-bottom: 0;
  height: 120px;
  border: none;
  position: sticky !important;
  top: 0;
  z-index: 50;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(0,0,0,.1);*/
  clear: both;
  transition: max-height 0.2s ease-out;
  margin-top: 0.5rem;
  /* @media (max-width: 1199.9px) {
      height: 80px; // TODO : change this
      clear: none;
      float: right;
      max-height: none;
  }*/
}
@media (max-width: 1130px) {
  .navbar {
    /*height: 80px;*/
    clear: none;
    float: right;
    max-height: none;
  }
}
.navbar .active {
  background-color: burlywood;
}

.header .navbar .dropdown-menu {
  border-radius: 12px;
  border: 1px solid #A4A6B2;
  padding: 0;
}
.header .navbar .dropdown-menu a {
  color: #F15A23;
}
.header .navbar .dropdown-menu a:first-of-type {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.header .navbar .dropdown-menu a:last-of-type {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.header .navbar .dropdown-menu a:hover {
  color: #FFFFFF;
  background-color: #F15A23;
}

.navbar-brand {
  display: block;
  margin-top: 0;
  padding: 0;
}

.navbar-brand img {
  max-height: 100%;
  height: auto;
  width: 100%;
  max-width: 300px;
}
@media (max-width: 1420px) {
  .navbar-brand img {
    max-width: 210px;
    width: 100%;
  }
}
@media (max-width: 1199.9px) {
  .navbar-brand img {
    height: 46px;
    margin: 17px 0;
  }
}

.navbar-toggler {
  font-size: 2rem;
  padding-right: 0;
  margin-top: 10px;
}

.dropdown-toggle::after {
  border-top: 0.425em solid;
  vertical-align: 0.15em;
  border-right: 0.35em solid transparent;
  border-left: 0.35em solid transparent;
}

.nav > li > a {
  transition: all 0.3s ease-in-out;
  line-height: 36px;
  font-weight: normal;
  /* font-size: 1em;*/
}

.dropdown-menu {
  border: none;
  color: black;
  border-radius: 0 0 5px 5px;
  left: auto;
  top: 34px;
  right: -50px;
  margin: 0;
  padding: 32px 5px 5px;
  min-width: 210px;
  background-color: white;
  font-size: 1em;
}

.dropdown-menu::before {
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #F1F1F1;
  position: absolute;
  top: 17px;
  left: 155px;
}
@media (max-width: 1199.9px) {
  .dropdown-menu::before {
    left: 116px;
  }
}

.dropdown {
  position: initial;
}

/*.dropdown-menu > li > a {
display: block;
border-top: 1px solid rgba(255, 255, 255, 0.2);
color: $grey-mid;
//padding: 10px 50px 10px 20px;*/
/* background-color: $primary-orange;*/
/*&:hover {
        color: $white;
    }

    &:active {
        color: yellowgreen; //TODO: change color as per proto type
    }
}

.dropdown-menu > li > a.btn-back {
    display: none;
    border: none;
    border-radius: 0;
}

.dropdown-menu > li:first-child > a {
    border-top: none;
}

.dropdown-menu > li:last-child > a {
    border-radius: 0 0 5px 5px;
}*/
.third-level-menu > li > a {
  padding: 7px 30px 7px 40px;
  font-size: 0.9em;
}
.third-level-menu > li > a:hover, .third-level-menu > li > a:focus, .third-level-menu > li > a.focus {
  background-color: rgba(69, 69, 69, 0.95);
  color: #FFFFFF;
  padding-left: 50px;
  padding-right: 20px;
}

.navbar-collapse {
  justify-content: flex-end;
}

.navbar-nav {
  margin-top: 0;
}

.navbar-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

/**desktop */
@media (min-width: 1199.9px) {
  .navbar > .container .navbar-brand {
    margin-left: 30px;
  }
  #SearchIcon span {
    display: none;
  }
  /* .nav > li > a {

      @media (max-width: 1375px) {
          font-size: 0.95em;
      }

      @media (max-width: 1360px) {
          font-size: 0.9em;
      }

      @media (max-width: 1250px) {
          font-size: 0.85em;
      }

      @media (max-width: 1315px) {
          //font-size: 0.8em;
      }
  }*/
  .navbar-nav > .nav-item.dropdown {
    position: relative;
  }
  .navbar-nav > .open > .dropdown-menu {
    position: absolute;
  }
  /* .dropdown-menu {
      @media (max-width: 1375px) {
          font-size: 0.95em;
      }

      @media (max-width: 1360px) {
          font-size: 0.9em;
      }

      @media (max-width: 1250px) {
          font-size: 0.85em;
      }
  }*/
  .upper-nav-container .header li {
    margin-right: 20px;
  }
  .third-level-menu {
    position: initial;
  }
  .nav-link {
    color: #454545;
  }
  .nav-link:hover, nav-link:active, nav-link.active, .child-page {
    color: #F15A23;
  }
  a.child-page, li.nav-item > a.active {
    color: #F15A23;
  }
  .navbar-expand-lg .navbar-nav {
    color: #454545;
    font-size: 1.9rem;
  }
  .navbar-expand-lg .navbar-nav:hover, .navbar-expand-lg .navbar-nav:focus, .navbar-expand-lg .navbar-nav.focus, .navbar-expand-lg .navbar-nav.active {
    color: #F15A23;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 0;
    margin-left: 1em;
    font-weight: 600;
  }
  /* .dropdown-item.active, .dropdown-item:active {
      color: $white;
      background-color: $primary-orange;
  }*/
}
@media (min-width: 1199.9px) and (min-width: 1199.9px) and (max-width: 1600px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
  }
}
@media (min-width: 1199.9px) {
  .dropdown-item {
    padding: 0.4em 1.25em;
  }
}
@media (min-width: 1199.9px) {
  #phone {
    padding: 0.75rem 0 0.75rem 1rem;
    margin-left: 8px;
  }
}
/****devices*****/
@media (max-width: 1199.9px) {
  .navigation {
    top: 0;
    left: 0;
    z-index: 98;
  }
  .nav > li > a {
    font-size: 13px;
    transition: all 0.3s ease-in-out;
    color: brown;
    text-align: left;
    vertical-align: middle;
    font-family: "Comfortaa";
    border-bottom: 1px solid #FFFFFF;
  }
  .nav .open > a,
  .nav .open > a:hover,
  .nav .open > a:focus {
    background-color: #F15A23;
    border-color: rgba(255, 255, 255, 0.2);
    color: #FFFFFF;
  }
  #slide-navbar-collapse {
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 99;
    width: 100%;
    height: calc(100vh - 80px);
    padding: 0;
    margin: 0;
    overflow-y: scroll;
    border: none;
  }
  #SearchIcon span {
    display: inline-block;
    margin-left: 10px;
    font-family: "Helvetica Neue", Helvetica, Arial, "Noto Sans", sans-serif;
    font-weight: 400;
  }
  .navbar-collapse {
    border-top: none;
    padding: 0;
    margin-right: 0;
  }
  .navbar-toggler {
    margin-top: 0;
  }
  .nav-link:hover {
    background-color: #D0410D;
  }
  .dropdown-menu::before {
    display: none;
  }
  .chevron-right {
    width: 12px;
    height: 19px;
    display: block;
    float: right;
  }
  .caret {
    display: none;
  }
  .navbar-nav .open .dropdown-menu {
    width: 100%;
  }
  .navbar-collapse.active {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
  .navbar-nav {
    flex: 0 0 100%;
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 208px;
    min-height: calc(100% - 208px);
    background-color: transparent;
  }
  .nav-link {
    display: block;
  }
  .dropdown-menu {
    border: none;
    margin: 0;
    padding: 0;
  }
  /*.dropdown-menu > li > a {
  line-height: 3em;
  color: white;
  font-size: 1em;*/
  /*background-color: $primary-orange;*/
  /*text-align: center;
      align-items: center;
      height: 3em;
      padding: 0;
  }*/
  /* .dropdown-item.active, .dropdown-item:active {
      background-color: $secondary-hover;
  }*/
  /* .dropdown-menu > li:last-child > a {
      border-radius: 0;
  }*/
  .navbar-buttons {
    padding: 0.7em 1em 50px;
    flex-direction: column;
    width: 100%;
    align-items: center;
    background-color: #F15A23;
    margin: 0;
  }
  .upper-nav-container .header li a:hover {
    color: #FFFFFF;
  }
  .upper-nav-container .header ul {
    background-color: transparent;
  }
  .header .navbar .dropdown-menu a {
    color: #FFFFFF;
    font-family: "Comfortaa";
    font-size: 13px;
    padding: 12px 20px 12px 30px;
  }
  header .navbar .dropdown-menu {
    border-radius: 0 !important;
    border: none !important;
  }
  header .navbar .dropdown-menu a:first-of-type {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: 1px solid #FFFFFF;
  }
  header .navbar .dropdown-menu a:last-of-type {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: 1px solid #FFFFFF;
  }
}
/*  Mobile only (note: tallest phone iPhoneX 812px so buttons fixed in landscape mode)*/
@media (max-width: 812px) {
  /*#slide-navbar-collapse {
      height: calc(72% - 80px);
  }*/
  .navbar-buttons {
    position: static;
    bottom: 0;
    padding: 20px;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: flex-end;
    background-color: #F5F5F5;
    margin: 0;
  }
  .navbar-buttons a {
    width: 100%;
  }
  .btn-quote, .btn-login {
    width: 100%;
  }
  .btn-login {
    margin-top: 20px;
  }
  header .navbar .dropdown-menu {
    border-radius: 0 !important;
    border: none !important;
  }
  header .navbar .dropdown-menu a:first-of-type {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  header .navbar .dropdown-menu a:last-of-type {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  header .navbar .dropdown-menu .dropdown-divider:last-of-type {
    display: none;
  }
}
/*@media (min-width: 1199.9px) and (max-width: 1319px) {
    .nav-link,
    .nav > li > a {
        font-size: 1.55rem;
    }
}*/
.animated-icon {
  width: 38px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

*:focus, *:active {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}

.animated-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  background: #F15A23;
}
.animated-icon span .animated-icon {
  width: 30px;
  height: 20px;
  position: relative;
  margin: 0px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
.animated-icon span *:focus, .animated-icon span *:active {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
}
.animated-icon span .animated-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  background: #F15A23;
}
.animated-icon span .animated-icon span:nth-child(1) {
  top: 0px;
}
.animated-icon span .animated-icon span:nth-child(2) {
  top: 10px;
}
.animated-icon span .animated-icon span:nth-child(3) {
  top: 20px;
}
.animated-icon span .animated-icon.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.animated-icon span .animated-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
  border: none;
}
.animated-icon span .animated-icon.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.animated-icon span:nth-child(1) {
  top: 0px;
}

.animated-icon span:nth-child(2) {
  top: 10px;
}

.animated-icon span:nth-child(3) {
  top: 20px;
}

.animated-icon.open span:nth-child(1) {
  top: 11px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}

.animated-icon.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
  border: none;
}

.animated-icon.open span:nth-child(3) {
  top: 11px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: Poppins;
}

.mainBody {
  position: relative;
  display: block;
  margin-top: -10px;
}
@media (max-width: 1129px) {
  .mainBody {
    margin-top: 70px;
  }
}
@media (min-width: 1131px) {
  .mainBody {
    margin-top: 97px;
  }
}

/*Brand For Logo text-styling*/
/* If the logo background is different from the cookie popup background, this will round the corners 25px is about right for full rounded */
/* Button Styling */
/* 5px, just enough not to be square */
.cookie-container {
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.divoverlay {
  position: fixed; /* Positioning and size */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #FEEEE8; /* color */
  display: none; /* making it hidden by default */
}





/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F15A23;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #F15A23;
}

/* Cookie Dialog */
@media screen and (max-width: 700px) {
  #gdpr-cookie-message {
    max-height: 96%;
    height: auto;
    overflow-y: auto;
  }
}
@media screen and (max-width: 400px) {
  #gdpr-cookie-message {
    max-height: 96%;
    height: auto;
    overflow-y: auto;
  }
}
@media screen and (max-width: 1024px) {
  button#ihavecookiesBtn {
    bottom: 0px;
    width: 100%;
    padding-left: 0px;
    min-width: 340px;
    max-width: 40%;
    padding-left: 0px;
  }
}
@media screen and (max-width: 1130px) {
  button#ihavecookiesBtn {
    bottom: 0px;
    padding-left: 0px;
  }
}
div.cookie-content {
  margin-left: 30%;
  padding: 1px 5px;
}

#gdpr-cookie-dialog {
  z-index: 99;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 90%;
  max-width: 600px;
  max-height: 90%;
  background-color: white;
  padding: 20px;
  border: none;
  margin: 0;
}

#gdpr-cookie-message, #gdpr-cookie-dialog div > * {
  box-sizing: border-box;
}

#gdpr-cookie-message div > .column {
  float: left;
  width: 50%;
  padding: 10px;
}

#cookieTitle {
  color: #000;
  text-align: center;
  font-weight: 600;
  font-family: museo-slab, "Roboto Slab", serif;
  padding: 0 0 0.5em;
  border: none;
  outline: none;
  margin: 0.5em 0 0 0;
}

.cookie-type-collapsible {
  background-color: white;
  color: #f15a23;
  cursor: pointer;
  padding: 1px 2px;
  width: 16px;
  height: 16px;
  border: solid 1px #f15a23;
  text-align: center;
  outline: none;
  font-size: 13px;
  margin-left: 1em;
  border-radius: 8px;
  line-height: 9px;
  display: inline-block;
}

#gdpr-cookie-message li {
  list-style: none;
  margin-bottom: 20px;
  display: block;
}

#gdpr-cookie-message li:last-child {
  margin-bottom: 0;
}

.cookie-type-collapsible:hover {
  background-color: #F15A23;
  color: white;
}

.cookie-type-collapsible > button {
  border-radius: 0;
  border-width: 0;
}

.cookie-type-desc {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: white;
}

#cookie-container-button-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

#gdpr-cookie-message h1 {
  flex-direction: column;
  text-align: center;
}

#gdpr-cookie-message h4 {
  color: #000;
  font-family: "Quicksand", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

#gdpr-cookie-message h5 {
  color: #000;
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 10px;
}

#gdpr-cookie-message p, #gdpr-cookie-message ul {
  color: #000;
  font-size: 15px;
  line-height: 1.5em;
}

#gdpr-cookie-message p:last-child {
  margin-bottom: 0;
}

#gdpr-cookie-message a {
  color: #F15A23;
  text-decoration: none;
  /*font-size: 15px;*/
  padding-bottom: 2px;
  border-bottom: 1px dotted rgba(255, 255, 255, 0.75);
  transition: all 0.3s ease-in;
}

#gdpr-cookie-message a:hover {
  color: #000;
  border-bottom-color: #F15A23;
  transition: all 0.3s ease-in;
}

#gdpr-cookie-message a[target=_blank]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==); /*url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);*/
  margin: 0px 3px 0px 5px;
}

#gdpr-cookie-message button {
  font-family: "Quicksand", sans-serif;
  font-size: 15px;
  font-weight: 500;
  padding: 7px;
  /*margin-left: 10%;*/
  margin-bottom: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  width: 200px;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;
  border-color: #F15A23;
  flex: 0 1 auto;
}

button#gdpr-cookie-advanced {
  background: white;
  color: #F15A23;
}

button#gdpr-cookie-necessary {
  background: white;
  color: #F15A23;
}

button#gdpr-cookie-accept {
  background: #F15A23;
  color: white;
}

button#gdpr-cookie-save-preferences {
  background: #F15A23;
  color: white;
}

#gdpr-cookie-message-x, button:disabled {
  opacity: 0.3;
}

#gdpr-cookie-message input[type=checkbox] {
  float: none;
  margin-top: 0;
  margin-right: 5px;
}

/*Partner Page Styling*/
.partner-list-grid-container {
  display: grid;
  grid-template-columns: 20% 20% 20% auto;
  font-family: Muli, Arial, "Helvetica Neue", Helvetica, sans-serif;
  background: white;
}

.partner-list-grid-item {
  border-bottom: 1px solid #f15a23;
  border-left: 1px solid #f15a23;
  padding: 2px;
  text-align: left;
  font-size: small;
  padding: 8px;
}

/* First 4 items */
.partner-list-grid-item:nth-child(-n+4) {
  border-top: 1px solid #f15a23;
}

/* Every 4th child */
.partner-list-grid-item:nth-child(4n) {
  border-right: 1px solid #f15a23;
}

.partner-list-grid-item:nth-child(4n+2),
.partner-list-grid-item:nth-child(4n+3) {
  word-break: break-all;
}

.ie-11-notification {
  display: none;
}

@media (max-width: 576px) {
  .partner-list-grid-container {
    grid-template-columns: auto auto auto auto;
  }
}
/* IE fixes */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cookie-container {
    display: block;
  }
  #gdpr-cookie-dialog {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  #gdpr-cookie-message button {
    margin-right: 20px;
  }
  .partner-list-grid-container {
    display: block;
  }
  .partner-list-grid-item {
    display: inline-block;
    vertical-align: top;
    width: 20%;
    height: auto;
    min-height: 180px;
  }
  .partner-list-grid-item:nth-child(4n) {
    width: 40%;
  }
  .partner-list-grid-item:nth-child(-n+4) {
    min-height: auto;
  }
  .ie-11-notification {
    display: block;
  }
}
/* Edge fixes */
@supports (-ms-accelerator: true) {
  #gdpr-cookie-message button {
    margin-right: 20px;
  }
}
/* Edge 16+ fixes */
@supports (-ms-ime-align: auto) {
  #gdpr-cookie-message button {
    margin-right: 20px;
  }
}
.homepageBanner {
  position: relative;
  height: 590px;
  width: 100%;
  overflow: hidden;
  color: white;
  /*.banner-container {
  	transform-origin: 0px 0px;
  	transform: scale(0.6594);
  	height: 591px;
  	position: absolute;*/
  /*width: 101%;*/
  /*min-height: 100%;*/
  /*object-fit: cover;*/
  /*}*/
}
.homepageBanner .homepageImage {
  width: 101%;
  min-height: 100%;
  object-fit: cover;
}

.homepageBanner .wrapper .container {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: left;
}
.homepageBanner .wrapper .bt-container {
  margin-left: 15%;
}
.homepageBanner .wrapper .bannerTextLine1 {
  margin-top: 4%;
  font: normal normal bold 36px Poppins;
  margin-bottom: 0;
}
.homepageBanner .wrapper .bannerTextLine2 {
  font: normal normal bold 46px Poppins;
  margin: 0;
}
.homepageBanner .wrapper .bannerTextLine3 {
  font: normal normal 36px Poppins;
  margin: 0;
}
.homepageBanner .wrapper .banner-make-claim .btn-gaq {
  background-color: #f15a23;
  color: white;
  border: 1px solid #f15a23;
  opacity: 1;
  border-radius: 52px;
  opacity: 1;
  font-style: normal;
  font-weight: 600;
  height: 70px;
  min-width: 450px;
  font: normal normal 30px/36px Comfortaa;
  letter-spacing: 0px;
  text-align: left;
  padding: 0px 20px 0px 80px;
  margin-top: 4%;
}
.homepageBanner .wrapper .banner-make-claim .btn-gaq:hover {
  color: #F15A23;
  background-color: white;
  border-radius: 52px;
  border: 1px solid #F15A23;
}
.homepageBanner .wrapper .banner-make-claim .fa-arrow-circle-right {
  float: right;
  padding-top: 4px;
}
@media (max-width: 1024px) {
  .homepageBanner .wrapper .banner-make-claim {
    display: none;
  }
}
.homepageBanner .wrapper .experienceImage {
  padding-left: 10px;
}
.homepageBanner .wrapper .experienceImage img {
  max-height: 112px;
  padding: 5px;
}
.homepageBanner .wrapper .dropdown-divider {
  border-top-width: 3px;
  margin-left: 0.5rem;
}
.homepageBanner .wrapper .bannerTextLine3 {
  font: normal normal medium 36px Poppins;
  margin: 0;
}
.homepageBanner .wrapper .tp-widget {
  position: absolute;
  bottom: 0;
  width: 100%;
}
@media (max-width: 1025px) {
  .homepageBanner {
    height: 300px;
  }
  .homepageBanner .container-fluid {
    padding-left: 0px;
  }
  .homepageBanner .wrapper .bannerTextLine1 {
    margin-top: 10%;
    margin-bottom: 0;
  }
  .homepageBanner .wrapper .bannerTextLine2 {
    font: normal normal bold 25px Poppins;
    margin-top: 1em;
    margin: 0;
  }
  .homepageBanner .wrapper .bannerTextLine3 {
    font: normal normal 20px Poppins;
    margin: 0;
  }
  .homepageBanner .wrapper .bt-container {
    margin-left: 0.1%;
  }
}
@media (max-width: 992px) {
  .homepageBanner .wrapper .bannerText {
    font-size: 2rem;
  }
}
@media (max-width: 767.98px) {
  .homepageBanner {
    height: 300px;
  }
  .homepageBanner .wrapper .tp-widget {
    margin-top: 1em;
  }
  .homepageBanner .wrapper .bannerText {
    font-size: 23px;
    margin-top: 2em;
  }
  .homepageBanner .wrapper .experienceImage {
    max-width: 30%;
    padding-left: 0;
  }
  .homepageBanner .wrapper .experienceImage img {
    max-width: 100%;
  }
}
@media (max-width: 1027px) {
  .bw-footer {
    margin-bottom: 289px;
  }
}

.bw-footer-top {
  padding: 25px 112px;
  border-bottom: 0.5px solid #969696;
}
@media (max-width: 1027px) {
  .bw-footer-top {
    padding: 25px 80px;
  }
}
@media (max-width: 1350px) {
  .bw-footer-top {
    padding: 25px 20px;
  }
}
@media (max-width: 1027px) {
  .bw-footer-top .footer__top__middle.l2 {
    width: 25%;
  }
}
@media (max-width: 600px) {
  .bw-footer-top .footer__top__middle.l2 {
    width: 100%;
  }
}
.bw-footer-top .footer__top__left {
  margin-top: 10px;
}
@media (max-width: 1027px) {
  .bw-footer-top .footer__top__left {
    margin-top: 0;
  }
  .bw-footer-top .footer__top__left.l1 {
    width: 75%;
  }
}
@media (max-width: 600px) {
  .bw-footer-top .footer__top__left.l1 {
    width: 100%;
  }
}
.bw-footer-top .footer__top__left ul li:last-child a label#hiddenlabel {
  display: none;
}
.bw-footer-top .footer-links li a {
  font-size: 16px;
}

.bw-footer-bottom p {
  padding: 15px 20px;
  line-height: 24px;
  width: 100%;
  font-size: 16px;
  color: black;
  overflow-wrap: break-word;
}
@media (max-width: 1027px) {
  .bw-footer-bottom p {
    padding: 10px 40px;
  }
}
@media (max-width: 600px) {
  .bw-footer-bottom p {
    text-align: center;
  }
}
@media (width: 810px) and (height: 1010px) {
  .bw-footer-bottom p {
    text-align: left;
    padding-left: 30px;
  }
}
.bw-footer-bottom a {
  color: #fff;
  text-decoration: none;
}

.footer {
  position: relative;
  background-color: burlywood;
  font-family: Poppins;
  font-size: 14px;
  width: auto;
}
.footer__top {
  background-color: burlywood;
  border-block-color: #ffffff;
  padding-top: 0.5px;
  padding-bottom: 0.5px;
}
.footer__top__left img {
  max-height: 50px;
}
.footer__top__middle {
  display: block;
  padding: 0 45px 0px 45px;
  text-align: center;
  height: 100%;
}
.footer__top__middle p {
  float: right;
  color: burlywood;
  font-size: 14px;
}
.footer__top__right__icons {
  margin-top: 3px;
  margin-left: 10px;
}
.footer__top__right__icons ul {
  list-style-type: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
}
.footer__top__right__icons img {
  padding: 3px;
  width: 45px;
  height: auto;
}

.footer__bottom {
  background-color: burlywood;
  border-block-color: #ffffff;
  padding-bottom: 0.5px;
  color: #ffffff;
}
.footer__bottom p {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 300;
}
.footer__bottom a {
  color: brown;
}
.footer__bottom__left .removepadding {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 300;
}
.footer__bottom__right p {
  text-align: right;
  margin: 14px 0 0 0;
}
.footer-footer {
  width: 100%;
}

.removehref a {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

.footer-links {
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgb(255, 255, 255);
}
.footer-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.footer-links li {
  float: left;
}
.footer-links li a {
  display: inline;
  padding: 8px;
  text-decoration: none;
  color: brown;
}

.sociallinks {
  padding-left: 5px;
}

@media (max-width: 1350px) {
  .addspaceftr {
   
    padding-bottom: 25px;
  }
}
@media (max-width: 1130px) {
  .footer__top__middle {
    padding: 0;
  }
  .header{
    height: 70px;;
  }
  .firsttable{		
		background-color: burlywood;
		position: -webkit-sticky;
		position: sticky;
		z-index: 990;
		padding-left:50px;
		top:100px;
	  }
}
@media (max-width: 991.98px) {
  .header{
    height: 70px;;
  }
  .firsttable{		
		background-color: burlywood;
		position: -webkit-sticky;
		position: sticky;
		z-index: 990;
		padding-left:50px;
		top:100px;
	  }
  .footer__top {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color:burlywood;
    border: 20px solid #969696;
    padding-bottom: 0.5px;
  }
  .footer__top__left img {
    margin-top: 17px;
    max-width: 100%;
    max-height: 40px;
  }
  .footer__top__middle {
    padding: 0 20px 1em 20px;
  }
  .footer__top__middle p {
    margin: 0;
  }
  .footer__top__middle__icons {
    margin-top: 10;
    width: 100%;
    padding: 10%;
  }
  .footer__top__right img {
    padding-top: 17px;
  }
}
@media (max-width: 575.98px) {
  .footer-links ul {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0;
  }
  .footer-links li {
    float: none;
  }
  .footer-links li a {
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
  .footer-links #hiddenlabel {
    visibility: hidden;
  }
  .footer__top {
    background-color:burlywood;
    padding: 20px;
  }
  .footer__top__left {
    text-align: center;
  }
  .footer__top__left img {
    margin-top: 17px;
    text-align: center;
  }
  .footer__top__middle {
    display: block;
    margin: 0;
    text-align: center;
    padding-bottom: 0;
  }
  .footer__top__middle p {
    float: left;
    color:burlywood;
    font-size: 14px;
    margin-left: 3em;
  }
  .footer__top__middle__icons {
    float: left;
    margin: 0;
    padding-top: 5px;
  }
  .footer__top__middle__icons img {
    margin: 0;
  }
  .footer__top__right {
    text-align: left;
  }
  .footer__bottom {
    padding: 0px 0px 250px 0px;
  }
  .footer__bottom p {
    text-align: center;
    margin-bottom: 0;
  }
  .footer__bottom__right p {
    text-align: center;
    margin: 14px 0 14px 0;
  }
}
@media (max-width: 767.98px) {
  .footer-links ul {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0;
  }
  .footer-links li a {
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
}
.footer {
  position: relative;
  background-color: burlywood;
}
.footer__top {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 0.5px solid #969696;
  padding-bottom: 0.5px;
}
.footer__top__left img {
  max-height: 50px;
}
.footer__top__middle {
  display: block;
  padding: 0 45px 0px 45px;
  text-align: center;
  height: 100%;
}
.footer__top__middle p {
  float: left;
  color:burlywood;
  font-size: 14px;
}
.footer__top__middle__icons {
  margin-top: 3px;
  margin-left: 10px;
}
.footer__top__middle__icons ul {
  list-style-type: none;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
}
.footer__top__middle__icons img {
  width: 32px;
  height: auto;
}
.footer__top__right {
  text-align: left;
  height: 80px;
}
.footer__top__right img {
  max-height: 70px;
}
.footer__bottom {
  background-color:burlywood;
  padding-bottom: 0.5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color:burlywood;
  border: 0.5px solid #969696;
}
.footer__bottom p {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 300;
}
.footer__bottom a {
  color: #ffffff;
}
.footer__bottom__left .removepadding {
  font-size: 14px;
  line-height: 1.3;
  font-weight: 300;
}
.footer__bottom__right p {
  text-align: right;
  margin: 14px 0 0 0;
}
.footer-footer {
  width: 100%;
}

@media (max-width: 1027px) {
  .footer__top__middle {
    padding: 0;
  }

}
@media (max-width: 991.98px) {
  .footer__top {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    background-color:burlywood;
    border: 0.5px solid #969696;
    padding-bottom: 0.5px;
  }
  .footer__top__left img {
    margin-top: 17px;
    max-width: 100%;
    max-height: 40px;
  }
  .footer__top__middle {
    padding: 0 20px 1em 20px;
  }
  .footer__top__middle p {
    margin: 0;
  }
  .footer__top__middle__icons {
    margin-top: 0;
    width: 100%;
  }
  .footer__top__right img {
    padding-top: 17px;
  }
}
@media (max-width: 575.98px) {
  .footer__top {
    background-color:burlywood;
    padding: 20px;
  }
  .footer__top__left {
    text-align: right;
  }
  .footer__top__left img {
    margin-top: 17px;
    text-align: center;
  }
  .footer__top__middle {
    display: block;
    margin: 0;
    text-align: center;
    padding-bottom: 0;
  }
  .footer__top__middle p {
    float: left;
    color:burlywood;
    font-size: 14px;
    margin-left: 3em;
  }
  .footer__top__middle__icons {
    float: left;
    margin: 0;
    padding-top: 5px;
  }
  .footer__top__middle__icons img {
    margin: 0;
  }
  .footer__top__right {
    text-align: center;
  }
  .footer__bottom {
    padding: 0px 0px 240px 0px;
  }
  .footer__bottom p {
    text-align: center;
    margin-bottom: 0;
  }
  .footer__bottom__right p {
    text-align: center;
    margin: 14px 0 14px 0;
  }
  .footer .addspace1 {
    padding: 0px;
  }
}
.QuickQuote {
  background-color: #2B1A3D;
  padding: 10px;
  display: block;
  box-shadow: 8px 7px 10px 0 rgba(19, 18, 18, 0.1);
  position: fixed;
  top: 117px;
  right: 0;
  left: 0;
  z-index: 2;
  font: normal normal bold 24px/36px Comfortaa;
}
@media screen and (max-width: 1130px) {
  .QuickQuote {
    padding-top: 10px;
    max-height: 200px;
    position: fixed;
    top: unset;
    bottom: 47px;
    z-index: 5;
  }
}
@media screen and (max-width: 600px) {
  .QuickQuote {
    padding-top: 10px;
  }
}
.QuickQuote__Container {
  margin: 0 auto;
  text-align: center;
}
.QuickQuote__Container select,
.QuickQuote__Container select.form-control {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background-repeat: no-repeat;
  background-size: 30px 11px;
  background-position: right 15px;
}
.QuickQuote .devicedropdown, .QuickQuote select.form-control, .QuickQuote .textareaformat {
  display: inline-block;
  min-width: 370px;
  max-width: 30%;
  height: 40px;
  border-radius: 20px;
  padding-left: 10px;
  margin: 10px 0;
  color: #2B1A3D;
  background-color: #FEEEE8;
  border-style: none;
  border-color: none;
  border-image: none;
  font-family: Poppins;
  font-size: 18px;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}
@media screen and (max-width: 1130px) {
  .QuickQuote .devicedropdown, .QuickQuote select.form-control, .QuickQuote .textareaformat {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .QuickQuote .devicedropdown, .QuickQuote select.form-control, .QuickQuote .textareaformat {
    min-width: 340px;
  }
}
@media screen and (min-width: 1131px) {
  .QuickQuote .devicedropdown, .QuickQuote select.form-control, .QuickQuote .textareaformat {
    margin: 10px;
  }
}
.QuickQuote .deviceMakeSearch {
  display: inline-block;
  padding-left: 15px;
  border-radius: 52px;
  padding-right: 7em;
  margin-right: 18px;
  font-size: 12px;
}
.QuickQuote .deviceModelSearch {
  display: inline-block;
  padding-left: 15px;
  border-radius: 52px;
  padding-right: 7em;
  margin-right: 18px;
  font-size: 12px;
}
@media screen and (max-width: 1130px) {
  .QuickQuote #changecontent {
    content: "Value of bike";
  }
}
.QuickQuote .searchDiv {
  display: inline-block;
}
@media screen and (max-width: 1130px) {
  .QuickQuote .searchDiv {
    width: 100%;
  }
}
.QuickQuote .searchDiv .Search__Button {
  background-color: rgb(241, 90, 35);
  color: white;
  border: 1px solid rgb(241, 90, 35);
  opacity: 1;
  border-radius: 52px;
  opacity: 1;
  font-style: normal;
  font-weight: 600;
  height: 40px;
  min-width: 370px;
  font: normal normal 20px poppins;
  letter-spacing: 0px;
  text-align: center;
  padding: 0px 20px 0px 0;
}
.QuickQuote .searchDiv .Search__Button:hover {
  color: #f15a23;
  background-color: white;
  border-radius: 52px;
  border: 1px solid rgb(241, 90, 35);
}
@media screen and (max-width: 1130px) {
  .QuickQuote .searchDiv .Search__Button {
    width: 100%;
    max-width: 100%;
    margin: 5px;
    padding-left: 10px;
    bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  .QuickQuote .searchDiv .Search__Button {
    min-width: 340px;
    margin: 5px 0;
  }
}
/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  font-family: poppins;
  background: white;
}

.custom-select select {
  display: none; /*hide original SELECT element:*/
}

.select-selected:before {
  position: absolute;
  content: "\f078";
  color: #BCBEC0;
  font-family: FontAwesome;
  top: 1px;
  right: 17px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
  font-size: 15px;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  /*position: absolute;
  color: #BCBEC0;
  content: "\f078";
  font-family: FontAwesome;
  top: 2px;
  right: 17px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;*/
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div, .select-selected {
  color: #BCBEC0;
  padding: 0 5em 0 0;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: #F6F7F9;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

#Why_Bicy {
  position: relative;
  width: 1385px;
  height: 785.019px;
  left: 256px;
  top: 1079.981px;
  overflow: visible;
  padding: 5px;
  -webkit-border-before: solid;
          border-block-start: solid;
  font: normal normal bold 15px Poppins;
}

#So_whether_you_use_your_bike_t {
  left: 23px;
  top: 268.019px;
  position: absolute;
  overflow: visible;
  width: 788px;
  height: 100px;
  line-height: 36px;
  margin-top: -8px;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: lighter;
  font-size: 20px;
  color: rgb(19, 17, 15);
  letter-spacing: -0.2px;
}

#Bicy_Insurance_is_designed_esp {
  left: 23px;
  position: absolute;
  overflow: visible;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgb(19, 17, 15);
  letter-spacing: -0.2px;
}

#heading_circle {
  position: relative;
  width: 88.174px;
  height: 97.559px;
  left: 7.63px;
  top: 36.414px;
  overflow: visible;
}

#heading_circle_bk {
  position: relative;
  width: 100%;
  height: 97.559px;
  left: 0px;
  top: 0px;
  overflow: visible;
  vertical-align: bottom;
}

#Whats_more_not_only_do_we_cove {
  left: 622px;
  top: 485.019px;
  position: absolute;
  overflow: visible;
  width: 764px;
  height: 244px;
  line-height: 36px;
  margin-top: -8px;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: lighter;
  font-size: 20px;
  color: rgb(19, 17, 15);
  letter-spacing: -0.2px;
}

#Why_Bicy_bl {
  left: 43px;
  top: 30.414px;
  overflow: visible;
  position: relative;
  width: 635px;
  height: 43px;
  line-height: 36px;
  margin-top: -3px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: rgb(241, 90, 35);
  letter-spacing: -0.2px;
}


.addspace1 {
  padding-bottom: 50px;
}

.Bicy_Insurance_is_designed_esp p:first-child {
  font-size: 22px;
}
.Bicy_Insurance_is_designed_esp p:nth-child(n+1) {
  font-size: 20px;
}

.Whats_more_not_only_do_we_cove p {
  font-size: 20px;
}

.whybicyContainer {
  padding: 5px;
  font: poppins;
}

#home-bike-1 {
  margin-top: -75px;
  width: 522px;
  height: 332px;
  vertical-align: top;
}

#home-bike-2 {
  margin-top: -75px;
  width: 476px;
  height: 364px;
}

@media (max-width: 1025px) {
  #home-bike-1, #home-bike-2 {
    width: 100%;
    height: auto;
    margin-top: 0;
  }
}
@media (max-width: 767.98px) {
  .interchange {
    display: flex;
    flex-direction: column-reverse;
  }
  #home-bike-1, #home-bike-2 {
    padding-top: 40px;
    flex: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: auto;
    width: 100%;
    margin-top: 5px;
  }
  #home-bike-2 {
    padding-top: 60px;
  }
  #heading_circle_bk {
    padding-top: 0;
  }
}
.whatDYGSection {
  background-color: rgba(43, 26, 61, 0.1019607843);
  /*margin-bottom: 15em;*/
}
.whatDYGSection .blackIcon {
  text-align: center;
  height: 300px;
}
@media (max-width: 767.98px) {
  .whatDYGSection .blackIcon {
    height: 190px;
    margin: 25px 0;
  }
}
.whatDYGSection .blackIcon .img {
  max-height: 75%;
}
.whatDYGSection .blackIcon p {
  font: normal normal 18px/22px Poppins;
  font-weight: bold;
  letter-spacing: 0px;
  color: #13110F;
  text-transform: capitalize;
  opacity: 1;
  text-align: center;
  padding-top: 120px;
  width: 100%;
  margin-left: 0;
  /*            margin-top: 90px;
  margin-left: 10%*/
}
@media (max-width: 767.98px) {
  .whatDYGSection .blackIcon p {
    padding-top: 60px;
  }
}
.whatDYGSection .blackIcon .check-square-icon {
  position: absolute;
  bottom: 75px;
  left: 48%;
}
@media (max-width: 767.98px) {
  .whatDYGSection .blackIcon .check-square-icon {
    bottom: 45px;
  }
}
.whatDYGSection .blackIcon .title2 {
  text-align: center;
  font: normal normal 300 16px/22px Poppins;
  letter-spacing: 0px;
  color: #13110F;
  text-transform: capitalize;
}
.whatDYGSection .whatDYGTilte {
  display: flex;
  flex-direction: row;
  padding: 76px 0 0 0;
  font: normal normal bold 30px/40px Poppins;
  color: #2B1A3D;
}
@media (max-width: 600px) {
  .whatDYGSection .whatDYGTilte {
    font-size: 20px;
    padding: 36px 0 0 0;
  }
}
.whatDYGSection .whatDYGTilte:before,
.whatDYGSection .whatDYGTilte:after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid #A4A6B2;
  margin: auto 10px auto 10px;
}
@media (max-width: 600px) {
  .whatDYGSection .whatDYGTilte:before,
  .whatDYGSection .whatDYGTilte:after {
    border-bottom: none;
  }
}
.whatDYGSection h2 {
  margin: 0;
  color: #353535;
  opacity: 85%;
  padding: 5px 0 0 0;
  font-weight: 300;
  text-align: center;
  font-size: 24px;
}
.whatDYGSection p {
  text-align: center;
  line-height: 1.2;
  margin: 0 30px 0 30px;
  color: #353535;
  opacity: 85%;
}

.whatDYGContainer {
  padding: 5px;
}

.whatDYGBottomline {
  text-align: center;
  font: normal normal bold 18px/22px Poppins;
  letter-spacing: 0px;
  color: #13110F;
  opacity: 1;
}

.whatDYGBtnContainer {
  text-align: center;
}

.whatDYGButton {
  background-color: rgb(241, 90, 35);
  color: white;
  border: 1px solid rgb(241, 90, 35);
  opacity: 1;
  border-radius: 52px;
  opacity: 1;
  font-style: normal;
  font-weight: 600;
  height: 46px;
  min-width: 334px;
  font: normal normal 24px/36px Comfortaa;
  letter-spacing: 0px;
  text-align: left;
  padding: 0px 20px 0px 80px;
  margin: 95px 0 65px;
}
.whatDYGButton.news-qt {
  min-width: 100%;
  padding: 0 20px 0 40px;
}
.whatDYGButton:hover {
  color: rgb(241, 90, 35);
  background-color: white;
  border-radius: 52px;
  border: 1px solid rgb(241, 90, 35);
}
@media (max-width: 767.98px) {
  .whatDYGButton {
    margin: 35px 0 47px;
  }
}

.categoryItem {
  margin-bottom: 3em;
}
@media (max-width: 1025px) {
  .categoryItem {
    margin-bottom: 0;
  }
}

.carousel-wave {
  height: 300px;
  width: 100%;
  position: absolute;
  bottom: -5px;
  background-size: 100%;
}

.thirdRowMargin {
  margin: 0 10%;
}

@media (max-width: 767.98px) {
  .whatsCoveredSection .blackIcon {
    min-height: 80px;
  }
  .categoryItem {
    margin-bottom: 0;
  }
}
.DidYouKnowSection .dYKHeading {
  font: normal normal bold 30px Poppins;
  color: #F15A23;
  text-align: center;
  letter-spacing: -0.6px;
  opacity: 1;
  margin: 59px 0 41px;
}
@media (max-width: 767.98px) {
  .DidYouKnowSection .dYKHeading {
    margin: 33px 0 30px;
    line-height: 36px;
  }
}
@media (max-width: 767.98px) {
  .DidYouKnowSection hr {
    width: 90%;
  }
}

@media (max-width: 767.98px) {
  .DidYouKnowSection .dyk_heading_circle_bk {
    margin-top: 34px;
  }
}
.DidYouKnowSection .did_you_know_title {
  left: 43px;
  top: 30.414px;
  overflow: visible;
  position: relative;
  width: 635px;
  height: 43px;
  line-height: 36px;
  margin-top: -3px;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: rgb(241, 90, 35);
  letter-spacing: -0.2px;
  /* divider color  #A4A6B2*/
}
.DidYouKnowSection .quotation-mark-text {
  padding-top: 80px;
  overflow-wrap: break-word;
  color: #484D6D;
}
@media (max-width: 767.98px) {
  .DidYouKnowSection .quotation-mark-text {
    padding-top: 40px;
    width: 90%;
    margin: 0 auto 26px auto;
  }
}
@media (max-width: 1025px) {
  .DidYouKnowSection .quotation-mark-text {
    padding-top: 40px;
  }
}
.DidYouKnowSection .quotation-mark-text span {
  font: normal normal 300 24px Poppins;
  letter-spacing: -0.48px;
}
.DidYouKnowSection .quote-left-icon {
  font-size: 28px;
  color: rgba(72, 77, 109, 0.431372549);
  margin-right: 10px;
}
.DidYouKnowSection .quote-right-icon {
  font-size: 28px;
  color: rgba(72, 77, 109, 0.431372549);
  font-weight: normal;
  float: right;
}
.DidYouKnowSection .dYKImage {
  float: right;
}
@media (max-width: 767.98px) {
  .DidYouKnowSection .dYKImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}
.DidYouKnowSection .m1 {
  margin-top: 66px;
  margin-bottom: 46px;
}
@media (max-width: 767.98px) {
  .DidYouKnowSection .m1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}
.DidYouKnowSection .flex-Item-Order1 {
  order: 1;
}
.DidYouKnowSection .flex-Item-Order1 .dYKImage {
  float: left;
}
@media (max-width: 767.98px) {
  .DidYouKnowSection .flex-Item-Order1 .dYKImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    float: none;
  }
}
.DidYouKnowSection .flex-Item-Order2 {
  order: 2;
}
.DidYouKnowSection .dYKFooter {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #585858;
  opacity: 1;
  margin: 70px 0 90px;
}
@media (max-width: 767.98px) {
  .DidYouKnowSection .dYKFooter {
    width: 90%;
    overflow-wrap: break-word;
    margin: 30px auto;
  }
}
@media (max-width: 1025px) {
  .DidYouKnowSection {
    width: 90%;
    margin: auto;
  }
}
@media (max-width: 1024px) and (min-height: 1366px) {
  .DidYouKnowSection {
    width: 100%;
  }
}
@media (min-width: 712px) and (max-width: 767px) {
  .DidYouKnowSection .col-sm-8 {
    max-width: 66.66667%;
  }
  .DidYouKnowSection .col-sm-4 {
    max-width: 33.33333%;
  }
}

.accordianContainer {
  margin-top: 320px;
  float: right;
  margin-right: 2em;
  padding: 0;
  margin-bottom: 3em;
  padding-bottom: 10em;
}

#Read_more_FAQs {
  left: 60px;
  top: 20px;
  position: absolute;
  overflow: hidden;
  width: 273px;
  height: 35px;
  line-height: 36px;
  margin-top: -3px;
  text-align: center;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  color: rgb(241, 90, 35);
}

.accordianSmallContainer {
  background-color: white;
}
.accordianSmallContainer a {
  text-decoration: none;
}
.accordianSmallContainer a button.btn-lnk.btn {
  font-size: 30px;
  height: 75px;
  min-width: 408px;
  margin-left: 0;
}
.accordianSmallContainer a button.btn-lnk.btn i.fas.fa-arrow-circle-right {
  margin-left: 15px;
}

.accordianSmall {
  padding-left: 0;
  padding-right: 0;
}

.accordion {
  background-color: white;
  color: #585858;
  cursor: pointer;
  padding: 18px;
  font: normal normal 600 24px Poppins;
  width: 100%;
  border-bottom: none;
  text-align: left;
  outline: none;
  transition: 0.4s;
  border-top: none;
  border-left: none;
  border-right: none;
  padding-right: 35px;
}
@media (max-width: 767.98px) {
  .accordion {
    font-size: 16px;
    padding: 10px 35px;
  }
}
.accordion span {
  float: left;
  width: 84%;
}

@media (max-width: 767.98px) {
  .panel p {
    font-size: 16px;
  }
}

@media (max-width: 767.98px) {
  .contactUSSection .accordion {
    width: 87.8%;
    font-size: 16px;
    padding: 10px 15px !important;
  }
  .contactUSSection .panel {
    width: 88%;
    padding: 0 18px;
  }
}

@media (max-width: 767.98px) {
  .faq-home .accordion {
    padding: 10px 18px !important;
  }
  .faq-home .accordion span {
    width: 83%;
  }
  .faq-home .panel {
    padding: 0 18px;
  }
}

.active, .accordion:hover {
  background-color: #FEEEE8;
}

#faq_bl {
  left: 53px;
  top: 30.414px;
  overflow: visible;
  position: relative;
  width: 635px;
  height: 43px;
  line-height: 36px;
  margin-top: -3px;
  text-align: right;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: rgb(241, 90, 35);
  letter-spacing: -0.2px;
}


.accordion-faq:hover {
  background-color: #FEEEE8;
}

.accordianSmallContainer .accordion:after, .accordianContainer .accordion:after {
  width: 15%;
  max-width: 40px;
  content: "+";
  color: white;
  font-weight: 800;
  float: right;
  margin-left: 5px;
  background-color: rgb(241, 90, 35);
  padding: 9px 15px 9px 15px;
  border-radius: 20px;
  padding: 5px 14px 5px 14px;
  border-radius: 20px;
  font-size: 20px;
}
.accordianSmallContainer .active:after, .accordianContainer .active:after {
  font-weight: 800;
  content: "−";
  background-color: #F15A23;
  color: white;
  padding: 5px 14px 5px 14px;
  border-radius: 20px;
  font-size: 20px;
}

.panel {
  padding-left: 2em;
  padding-right: 2em;
  background-color: white;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-out;
  border-bottom: #eee 1px solid;
  font: normal normal 300 18px Poppins;
}
.panel p, .panel ul {
  line-height: 1.3;
  color: #2B1A3D;
  opacity: 0.8;
}

.infolastsection .logo {
  margin: 42px auto 60px;
}
.infolastsection .content1, .infolastsection .content2 {
  font-size: 24px;
  text-align: center;
  letter-spacing: -0.48px;
  line-height: 36px;
}
.infolastsection .content1 p, .infolastsection .content2 p {
  width: 99%;
  padding: 0 1% 1%;
  margin: 0;
}
.infolastsection .content2 {
  color: #2B1A3D;
  font-size: 30px;
  letter-spacing: 0;
  font-weight: bold;
}
.infolastsection .content2 p:last-child {
  margin-bottom: 2%;
}
@media (max-width: 767.98px) {
  .infolastsection .logo {
    margin: 22px auto 30px;
  }
  .infolastsection .content1, .infolastsection .content2 {
    font-size: 18px;
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
  }
  .infolastsection .content2 {
    font-size: 24px;
  }
  .infolastsection hr {
    display: none;
  }
}
@media (max-width: 1025px) {
  .infolastsection hr {
    display: none;
  }
}

.content1 {
  font-style: normal;
  font-weight: lighter;
  font-family: Poppins;
  font: normal normal normal 18px Poppins;
}

.content2 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color:burlywood;
}

.logo {
  display: block;
  float: left;
  max-width: 210px;
  font-size: 2em;
  text-decoration: none;
  margin-top: 1rem;
}
.accordian-answer {
  line-height: 1.3;
  color: #353535;
  opacity: 0.8;
}

.addspace {
  padding-top: 50px;
  padding-bottom: 50px;
}
.addspace3 {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (max-width: 767.98px) {
  .addspace {
    padding-top: 30px;
    padding-bottom: 30px;
    display: none;
  }
}
@media (max-width: 1025px) {
  .addspace {
    display: none;
  }
}

.btn-lnk {
  background-color: white;
  color: #f15a23;
  margin-left: 12px;
  border: 1px solid #f15a23;
  opacity: 1;
  border-radius: 52px;
  opacity: 1;
  font-style: normal;
  font-weight: 600;
  height: 35px;
  min-width: 272px;
  font: normal normal 18px Comfortaa;
  letter-spacing: 0px;
  text-align: left;
  padding: 0px 20px 0px 50px;
  margin-top: 3%;
  font-family: Comfortaa;
  font-style: normal;
  font-weight: bold;
}
.btn-lnk:hover {
  color: white;
  background-color: #f15a23;
  border-radius: 52px;
  border: 1px solid #F15A23;
}

.faq-btn a button {
  transition: all 0.5s;
}
.faq-btn a button.btn-lnk.btn i {
  padding-right: 10px;
}
.faq-btn a button.btn-lnk.btn:hover i {
  padding-right: 0;
  margin-left: 35px !important;
}

@media (max-width: 767.98px) {
  .accordianSmallContainer {
    display: block;
  }
  .faq-btn {
    text-align: center;
  }
  .faq-btn a button {
    transition: all 0.5s;
  }
  .faq-btn a button.btn-lnk.btn {
    font-size: 24px;
    height: 46px;
    min-width: 334px;
  }
  .faq-btn a button.btn-lnk.btn i {
    padding-right: 10px;
  }
  .faq-btn a button:hover i {
    padding-right: 0;
  }
}
@media (max-width: 600px) {
  #faq_bl {
    padding-top: 0;
    font-size: 22px;
    top: 17px;
    left: 30px;
  }
}
#bicycleinsurance {
  position: relative;
  width: 1385px;
  height: 785.019px;
  left: 256px;
  top: 1079.981px;
  overflow: visible;
  padding: 5px;
  -webkit-border-before: solid;
          border-block-start: solid;
  font: normal normal bold 15px Poppins;
}

#Bicycleinsurancetitle {
  left: 43px;
  top: 30.414px;
  overflow: visible;
  position: relative;
  width: 635px;
  height: 43px;
  line-height: 36px;
  margin-top: -3px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  color: rgb(241, 90, 35);
  letter-spacing: -0.1px;
}

#BicycleInsurancecontent {
  left: 23px;
  position: absolute;
  overflow: visible;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  color: rgb(19, 17, 15);
  letter-spacing: -0.2px;
}

.bicycleinsuranceContainer {
  padding: 5px;
  font: poppins;
}

#bicycleinsuranceimg {
  width: 100%;
  height: auto;
  vertical-align: top;
}

.addspace1 {
  padding-bottom: 25px;
}

@media (max-width: 767.98px) {
  #heading_circle_bk {
    padding-top: 0;
  }
  #bicycleinsuranceimg {
    flex: auto;
    width: 100%;
    height: auto;
  }
  #BicycleInsurancecontent {
    left: 23px;
    position: absolute;
    overflow: visible;
    text-align: left;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    color: rgb(19, 17, 15);
    letter-spacing: -0.2px;
  }
  #Bicycleinsurancetitle {
    font-size: 22px;
  }
}
.productsGrid {
  text-align: center;
  color: white;
  padding-top: 73px;
  padding-bottom: 54px;
  background-color: rgba(43, 26, 61, 0.1019607843);
}
.productsGrid .check-square-icon {
  position: absolute;
  bottom: 25px;
  left: 45%;
}
.productsGrid h1 {
  color: #2B1A3D;
  margin: 2% 0;
  text-align: left;
}
.productsGrid img {
  max-width: 100%;
  max-height: 100%;
  padding: 2%;
}
.productsGrid .column {
  text-align: center;
  margin-bottom: 15px;
  padding: 10px;
}
.productsGrid__container ul {
  list-style-type: none;
  color: black;
  padding-left: 0;
  align-content: center;
  width: 90%;
  margin: auto;
}
.productsGrid__container ul li p {
  padding-bottom: 50px;
}
.productsGrid__container ul li p.title1 {
  margin: 0;
  padding: 0;
}
.productsGrid__container__product {
  background-color: white;
  margin: 1%;
  box-shadow: none;
  padding: 2%;
  transition: all 0.2s ease-in-out;
  height: 100%;
  text-align: center;
  border-radius: 15px;
}
.productsGrid__container__product h2 {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 100;
  padding: 1rem;
  margin: 0;
}
.productsGrid__container__product__title {
  text-align: center;
}
.productsGrid__container__product__title h3 {
  margin: 5px 0;
}
.productsGrid__container__product__title h3 p {
  color: black;
  font-size: 0.9em;
  text-decoration: none;
  margin: 0 auto;
  bottom: 0;
}
.productsGrid__container__product__image {
  text-align: center;
  font: normal normal 10px/12px Poppins;
  color: black;
}
.productsGrid__container .title1 {
  text-align: center;
  font: normal 600 22px/34px Poppins;
  letter-spacing: 0px;
  color: #2B1A3D;
  text-transform: capitalize;
}

@media (max-width: 990px) {
  .col-adjust {
    padding-left: 0px;
  }
  li .addspace {
    padding: 10px;
  }
}
.additionalBenefitsTitle {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin: 0 0 19px 0;
  font: normal bold 30px/40px Poppins;
  color: #2B1A3D;
}
@media (max-width: 600px) {
  .additionalBenefitsTitle {
    background-color: rgba(43, 26, 61, 0.1019607843);
    padding: 46px 0;
  }
}

@media (max-width: 767.98px) {
  #carouselmobile {
    border-bottom: 2px solid rgba(43, 26, 61, 0.1019607843);
  }
}
.removestyle ul {
  list-style-type: none;
  font: normal normal 10px/12px Poppins;
  color: black;
  padding-left: 2px;
  align-content: center;
}
.removestyle ul li .addspace {
  padding-top: 5px;
}

.additionalBenefitsTitle:before,
.additionalBenefitsTitle:after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid #A4A6B2;
  margin: auto 10px auto 10px;
}
@media (max-width: 767.98px) {
  .additionalBenefitsTitle:before,
  .additionalBenefitsTitle:after {
    border-bottom: none;
  }
}

.productsGrid__container__product {
  background-color: white;
  margin: 1%;
  box-shadow: none;
  padding: 2%;
  transition: all 0.2s ease-in-out;
  height: 100%;
  text-align: center;
  border-radius: 15px;
}
.productsGrid__container__product h2 {
  font-size: 1.5rem;
  line-height: 1.3;
  font-weight: 100;
  padding: 1rem;
  margin: 0;
}

@media (max-width: 767.98px) {
  .productsGrid__container__product {
    max-width: 100%;
    margin-bottom: 1em;
  }
  .productsGrid__container__product:hover {
    box-shadow: none;
    -webkit-transform: none;
            transform: none;
  }
  .productsGrid__container {
    margin: 0;
  }
  .productsGrid {
    padding-top: 1.5em;
  }
}
@media (max-width: 650px) {
  .productsGrid__container {
    margin-top: 1em;
  }
}
@media (min-width: 650px) {
  .containerMobile {
    display: none;
  }
  .containerDesktop {
    display: block;
  }
}
@media (max-width: 650px) {
  .containerDesktop {
    display: none;
    background-color: #e9f1f8;
  }
  .containerMobile {
    display: block;
  }
  h2 {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 100;
    padding: 1rem;
    margin: 0;
  }
  /*& img {
  	//max-width: 200px;
  	//max-height: 200px;
  }


  &__title {
  	text-align: center;

  	& h3 {
  		margin: 5px 0;

  		& p {
  			color: black;
  			font-size: 16px;
  			text-decoration: none;
  			margin: 0 auto;
  			bottom: 0;
  		}
  	}
  }

  &__image {
  	text-align: center;
  	font: normal normal 10px/12px Poppins;
  	color: black;
  }
  	*/
  .column {
    text-align: center;
    margin-bottom: 2%;
    padding: 10px;
  }
  .prodcontent {
    font: normal normal 300 20px/22px Poppins;
  }
  .title1 {
    text-align: center;
    font: bold normal 600 22px/24px Poppins;
    letter-spacing: 0px;
    color: #13110F;
    text-transform: capitalize;
  }
  .fa-2x {
    color: #484D6D;
  }
}
.page-bannerTextLine1 {
  margin-top: 4%;
  font: normal normal bold 38px Poppins;
  margin-bottom: 0;
}

.page-bannerTextLine2 {
  font: normal normal 1.375rem Poppins;
  margin: 0;
}

.page-bannerTextLine3 {
  font: normal normal bold 28px Poppins;
  margin: 0;
}
@media (min-width: 768px) {
  .page-bannerTextLine3 {
    font-weight: normal;
    font-size: 1.3rem;
  }
}
@media (min-width: 992px) {
  .page-bannerTextLine3 {
    font-weight: bold;
    font-size: 36px;
  }
}

.whatsCoveredSection {
  /*background: transparent url('../images/whats-covered-background.jpg') 0% 0% no-repeat padding-box;*/
  background-size: cover !important;
  background-position: center !important;
}
.whatsCoveredSection .blackIcon {
  text-align: center;
  height: 178px;
}
.whatsCoveredSection .blackIcon .img {
  max-height: 75%;
}
.whatsCoveredSection .whatsCoveredTilte {
  display: flex;
  flex-direction: row;
  padding: 76px 0 15px;
  font: normal normal bold 1.56rem Poppins;
  color: #FFFFFF;
  margin: 0;
  text-align: center;
}
.whatsCoveredSection .whatsCoveredTilte:before,
.whatsCoveredSection .whatsCoveredTilte:after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid #A4A6B2;
  margin: auto 10px auto 10px;
}

.whatsCoveredContainer {
  padding: 5px;
  -webkit-transform-origin: top;
          transform-origin: top;
  margin-top: 40px;
}
.whatsCoveredContainer .whatCoveredItem {
  margin-bottom: 3em;
  text-align: center;
}
.whatsCoveredContainer .whatCoveredItem p {
  font: normal normal 30px/40px Poppins;
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #F15A23;
  margin: 38px 0 7px;
}
.whatsCoveredContainer .whatCoveredItem .iconText {
  font: normal normal 20px/36px Poppins;
  color: #FFFFFF;
  padding-bottom: 4px;
}
@media (max-width: 767.98px) {
  .whatsCoveredContainer .whatCoveredItem {
    margin-bottom: 2em;
  }
}

.serviceWeProvideSection .serviceWPIcon {
  text-align: center;
  height: 97px;
}
.serviceWeProvideSection .serviceWPTilte {
  display: flex;
  flex-direction: row;
  padding: 70px 0;
  margin: 0;
  font: normal normal bold 30px/36px Poppins;
  letter-spacing: -0.6px;
  color: #F15A23;
}
.serviceWeProvideSection .serviceWPTilte:before,
.serviceWeProvideSection .serviceWPTilte:after {
  content: "";
  flex: 1 1;
  border-bottom: 2px solid #A4A6B2;
  margin: auto 10px auto 10px;
}
@media (max-width: 767.98px) {
  .serviceWeProvideSection .serviceWPTilte:before,
  .serviceWeProvideSection .serviceWPTilte:after {
    border-bottom: none;
  }
}

.serviceWPContainer {
  -webkit-transform-origin: top;
          transform-origin: top;
}
.serviceWPContainer .serviceWPItem {
  margin-bottom: 70px;
  text-align: center;
}
.serviceWPContainer .serviceWPItem p {
  font: normal normal 30px Poppins;
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #F15A23;
  margin: 36px 0 17px;
}
@media (max-width: 767.98px) {
  .serviceWPContainer .serviceWPItem p {
    font-size: 1.25rem;
  }
}
.serviceWPContainer .serviceWPItem .iconText {
  font: normal normal 1.25em Poppins;
  color: #13110F;
  padding-bottom: 10px;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .serviceWPContainer .serviceWPItem .iconText {
    font-size: 1.15rem;
    font-weight: 600;
  }
}
@media (max-width: 767.98px) {
  .serviceWPContainer .serviceWPItem {
    margin-bottom: 2em;
  }
}

.contactUSSection .contactUSIcon {
  text-align: center;
  height: 97px;
}


.headingCircleBackgroundTitle {
  left: 43px;
  top: 30.414px;
  overflow: visible;
  position: relative;
  width: 635px;
  height: 43px;
  line-height: 36px;
  margin-top: -3px;
  text-align: left;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 1.875rem;
  color: rgb(241, 90, 35);
  letter-spacing: -0.2px;
  /* divider color  #A4A6B2*/
}

.contactUSContainer {
  padding: 5px;
}
.contactUSContainer .contactUSItem {
  margin-bottom: 3em;
}
.contactUSContainer .contactUSItem p {
  font: normal normal 1.65rem Poppins;
  font-weight: bold;
  letter-spacing: -0.6px;
  color: #F15A23;
}
@media (max-width: 767.98px) {
  .contactUSContainer .contactUSItem p {
    font-size: 1.25rem;
  }
}
.contactUSContainer .contactUSItem .iconText {
  font: normal normal 20px Poppins;
  color: #13110F;
  padding-bottom: 10px;
}
.contactUSContainer .contactUSItem .cHeading {
  font-weight: bold;
  font-size: 22px;
}
.contactUSContainer .contactUSItem .cEmailPhone {
  color: #F15A23;
  font-weight: 600;
  word-break: break-word;
}
@media (max-width: 767.98px) {
  .contactUSContainer .contactUSItem {
    margin-bottom: 2em;
  }
}

.blog {
  background-color: #f6f6f6;
  font-family: Poppins;
  padding-top: 2em;
  padding-bottom: 1em;
}
.blog .blog-colum {
  text-align: left;
  padding: 10px 20px;
}
.blog .blog-colum a {
  color: #F15A23;
  text-decoration: none;
  font-weight: 600;
}
.blog ul {
  display: inline-block;
  text-decoration: none;
  padding-left: 0;
  text-align: left;
}
.blog ul li {
  display: inline-block;
  text-align: left;
  font-size: 18px;
  text-align: left;
  font-family: Poppins;
  color: #585858;
}
.blog .blog-detail {
  margin-top: 10px;
  margin-bottom: 0.5em;
}
.blog .readMore {
  font-size: 18px;
}

.blogIntro {
  margin-bottom: 1em;
}
.blogIntro .heading {
  font-size: 36px;
  color: white;
  margin: 0 0 17px 0;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
}
.blogIntro p {
  color: white;
  font-size: 20px;
  /*padding-top: 12px;*/
}
.blogIntro .blogSublineText {
  font-size: 20px;
}
.blogIntro .blog-getq-btn {
  -webkit-transform-origin: left;
          transform-origin: left;
}

.darkblue {
  background-color: #2B1A3D;
  height: 100%;
  border-radius: 15px;
  padding: 19px 7px 17px;
}

.blogContainer {
  margin-bottom: 1em;
}

.bgWhite {
  background-color: white;
  border: 1px solid #A4A6B2;
  height: 100%;
  border-radius: 15px;
}
.bgWhite img {
  height: 300px;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
}

.blogImage {
  padding: 0;
}

.blogSummarytitle {
  text-align: left;
  font-style: normal;
  font-size: 24px;
  font-family: Poppins;
  color: #13110F !important;
  opacity: 1;
  margin: 1rem 0 0rem 0;
}

.blogSummaryText {
  color: #13110F;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 300;
}

/* ================ Pagniation Section CSS..start here ============= */
.pagination {
  margin: 100px auto;
  background-color: #f6f6f6;
  text-align: center;
  padding-bottom: 30px;
}

.pagination__list {
  list-style-type: none;
}

.pagination__item {
  display: inline-block;
  margin-right: 0.25em;
}

.pagination__item a {
  padding: 3px 10px 3px 11px;
  color: #F15A23;
  font-weight: 500;
  text-decoration: none;
  border: 1px solid #F15A23;
}
.pagination__item a:hover {
  text-decoration: underline;
}

.pagination__item:last-child {
  padding-right: 0;
}
.pagination__item:last-child a {
  border: none;
}

.pagination__item:first-child a {
  border: none;
}

.pagination__item--active a, .current a {
  color: #FFFFFF;
  background-color: #F15A23;
  padding: 3px 13px;
}

/* ================ Pagniation Section CSS ..End Here============= */
.blogGridContent p {
  color: #13110F;
  font-size: 0.8rem;
}
.blogGridContent li {
  color: #13110F;
  font-size: 0.8rem;
}
.blogGridContent img {
  max-width: 100%;
  object-fit: cover;
}

.news__content {
  padding-right: 20px;
}
.news__content .publishDetails {
  font-size: 0.8rem;
}
.news__content .blog-backbtn-container {
  -webkit-transform: scale(0.6667);
          transform: scale(0.6667);
  -webkit-transform-origin: left;
          transform-origin: left;
}
.news__content .blog-backbtn {
  color: #F15A23;
  background-color: white;
  /*  margin-left: 12px;*/
  border: 1px solid #F15A23;
  opacity: 1;
  border-radius: 52px;
  opacity: 1;
  font-style: normal;
  font-weight: 600;
  height: 40px;
  min-width: 150px;
  font: normal normal 22px/36px Comfortaa;
  letter-spacing: 0px;
  text-align: right;
  padding: 0px 30px 0px 10px;
  margin-top: 3%;
  margin-bottom: 20px;
}
.news__content .blog-backbtn:hover {
  border-radius: 52px;
  border: 1px solid #F15A23;
  background-color: #F15A23;
  color: white;
}

.headingCircleBackgroundContent {
  padding-left: 0px;
}

.news__content .umb-grid {
  padding: 0;
}
.news__content .umb-grid .container-fluid {
  padding: 0;
}

.umb-grid {
  padding: 0 20px;
}
.umb-grid p {
  text-align: left !important;
}

.RelatedArticles {
  margin-top: 1.875em;
}
.RelatedArticles ul {
  padding: 0;
}
.RelatedArticles h2 {
  color: #585858;
  font: normal normal bold 24px Poppins;
}
.RelatedArticles li {
  color: #F15A23;
  padding: 15px 0;
}
.RelatedArticles li::marker {
  display: none;
  content: "";
}
.RelatedArticles li::before {
  content: "";
  background: #D0410D;
  height: 15px;
  width: 15px;
  display: block;
  float: left;
  margin-right: 15px;
}
.RelatedArticles h3 {
  color: #F15A23;
  font: normal normal 600 0.9rem Poppins;
  margin: -14px 0;
  width: 88.5%;
  float: left;
}
@media (max-width: 1024px) {
  .RelatedArticles h3 {
    width: 86.3%;
  }
}
.RelatedArticles h3 a {
  color: #F15A23;
  font: normal normal 600 18px/34px Poppins !important;
  width: 100%;
  text-align: left;
}
.RelatedArticles h3 a:after {
  background: none !important;
}
.RelatedArticles h3 a:after:hover {
  background: none !important;
}
.RelatedArticles h3 a span {
  display: inline-block;
  width: 92%;
  /*color: $primary-orange;*/
  /* font: normal normal 600 18px/34px Poppins;*/
}
.RelatedArticles h3 a span:after {
  display: inline-block;
  font-family: fontAwesome;
  content: "\f054";
  float: right;
  transition: 0.5s !important;
  /* margin-top: 3px;*/
}
.RelatedArticles h3 a:hover span:after {
  padding-left: 50px;
  float: none;
  transition: 0.5s;
}

.LatestSocialNewsContainer {
  color: #585858;
  font: normal normal bold 24px Poppins;
}

@media (max-width: 991px) {
  .RelatedArticles {
    margin: 2em 0 0 20px;
  }
  .LatestSocialNewsContainer {
    margin: 2em 0 0 20px;
  }
}

